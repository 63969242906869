/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';

import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Row
} from 'reactstrap';

import Loader from 'react-loader-spinner';

import Alert from 'views/components/Alert';
import PhotoGallery from 'views/components/PhotoGallery';
import LoaderModal from 'views/components/LoaderModal';

import AnosLetivosService from 'services/AnosLetivosService';
import DiariosService from 'services/DiariosService';
import EntesService from 'services/EntesService';
import EscolasService from 'services/EscolasService';
import LoginService from 'services/LoginService';
import RegistroDiarioAlunoService from 'services/RegistroDiarioAlunoService';
import TurmasService from 'services/TurmasService';
import UsuarioService from 'services/UsuarioService';

import TipoAvaliacao from 'assets/csv/tipos-avaliacao.json';
import Turno from 'assets/csv/turnos.json';

class RegistroDiarioAluno extends React.Component {
  constructor(props) {
    super(props);

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.state = {
      showAlert: false,
      showLoaderModal: false,

      informacoes: '',
      imagens: [],

      error: null,
    };

    this.loginService = new LoginService();
    this.entesService = new EntesService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.turmasService = new TurmasService();
    this.diariosService = new DiariosService();
    this.usuarioService = new UsuarioService();
    this.registroDiarioAlunoService = new RegistroDiarioAlunoService();
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);
    try {
      const dadosEnte = await this.entesService
        .carregarDadosEnte();

      const anoLetivo = await this.anosLetivosService
        .carregarAnoLetivo(this.props.match.params.idAnoLetivo);

      const escola = await this.escolasService
        .carregarEscola(this.props.match.params.idEscola);

      const turma = await this.turmasService
        .carregarTurma(this.props.match.params.idTurma);

      const diario = await this.diariosService
        .carregarDiario(this.props.match.params.idDiario);

      const registroDiarioAluno = await this.registroDiarioAlunoService
        .carregarRegistroDiarioAluno(this.props.match.params.idRegistroDiarioAluno);

      const fotos = await this.registroDiarioAlunoService
        .carregarRegistroDiarioAlunoFotos(this.props.match.params.idRegistroDiarioAluno);

      const imagens = fotos.map((foto) => ({
        ...foto,
        src: `${foto.url}?token=${sessionStorage.getItem('auth-token')}`
      }));

      this.setState({
        dadosEnte,
        anoLetivo,
        escola,
        turma,
        diario,
        registroDiarioAluno,
        imagens,
      });

    } catch (e) {
      this.setState({ erro: true });
    }
  }

  voltar() {
    if (this.state.turma.tipoAvaliacao === TipoAvaliacao.NOTA.value) {
      return `${this.props.layout}`
        + `/anosletivos/${this.props.match.params.idAnoLetivo}`
        + `/escola/${this.state.escola.id}`
        + `/turma/${this.state.turma.id}`
        + `/diario/${this.state.diario.id}`;
    }
    return `${this.props.layout}`
      + `/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.state.escola.id}`
      + `/turma/${this.state.turma.id}`
      + `/diario/${this.state.diario.id}/alfabetizacao`
      + `/registrosdiario/matricula/${this.state.registroDiarioAluno.matricula.id}`;
  }

  conteudoPagina() {
    return this.state.escola ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            {
              !this.state.turma.multiSeriado
                ? <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
                : <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turma.turmaMultiseriado.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
            }
            {
              !this.state.turma.multiSeriado
                ? <BreadcrumbItem><Link to={this.voltar()}>{this.state.diario.nome}</Link></BreadcrumbItem>
                : <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turma.turmaMultiseriado.id}/diario`}>Diário único</Link></BreadcrumbItem>
            }
            <BreadcrumbItem active>Registro de Diário do Aluno</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">
                Registro de Diário do aluno
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="5">
                  <FormGroup className={'has-label'}>
                    <label>Alunos</label>
                    <Input
                      disabled
                      value={this.state.registroDiarioAluno.matricula.usuario.nome}
                    />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup className={'has-label'}>
                    <label>Data</label>
                    <Input
                      disabled
                      value={this.state.registroDiarioAluno.data}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {
                this.state.loadingDiarioAluno ? <div align="center" style={{ padding: 30 }}>
                  <Loader
                    type="Oval"
                    color="#053d7c"
                    height="50"
                    width="50" />
                </div> : <Row>
                  <Col className="mt-3" md="12">
                    <Row>
                      <Col>
                        <FormGroup className={`has-label`}>
                          <label>Informações do dia *</label>
                          <Input
                            disabled
                            value={this.state.registroDiarioAluno.informacoes}
                            type="textarea" />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <PhotoGallery 
                          images={this.state.imagens}/>
                      </Col>
                    </Row>
                    <div className="category form-category">
                      * Campos obrigatórios
                    </div>
                  </Col>
                </Row>
              }
            </CardBody>
            <CardFooter>
              <Row>
                <Col className="text-left">
                  <Button
                    color="primary"
                    onClick={() => this.props.history
                      .push(this.voltar())} >
                    Voltar
                  </Button>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </> : <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#053d7c"
          height="50"
          width="50" />
      </div>
    </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text="Salvando notas..." />
      </div>
    );
  }
}

export default RegistroDiarioAluno;
