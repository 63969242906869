import DashboardGestor from 'views/gestor/DashboardGestor';
import Noticias from 'views/gestor/Noticias';
import Noticia from 'views/gestor/Noticia';
import CadastroNoticia from 'views/gestor/CadastroNoticia';
import Documentos from 'views/gestor/Documentos';
import RelatoriosEstatisticos from 'views/gestor/RelatoriosEstatisticos';

import ImprimirRelatorioPassageirosPorTransporte from 'views/gestor/ImprimirRelatorioPassageirosPorTransporte';
import ImprimirRelatorioPassageirosPorTurma from 'views/gestor/ImprimirRelatorioPassageirosPorTurma';
import ImprimirRelatorioDatasAnoLetivo from 'views/gestor/ImprimirRelatorioDatasAnoLetivo';
import ImprimirRelatorioMatriculasNaoRenovadas from 'views/gestor/ImprimirRelatorioMatriculasNaoRenovadas';

import RelatoriosEscolasTurmas from 'views/gestor/RelatoriosEscolasTurmas';
import RelatoriosTransportes from 'views/gestor/RelatoriosTransportes';
import ImprimirRelatorioTurmasPorEscola from 'views/gestor/ImprimirRelatorioTurmasPorEscola';
import ImprimirListagemRelatoriosTurmasNaoInformadosPorEscola from 'views/gestor/ImprimirListagemRelatoriosTurmasNaoInformadosPorEscola';
import ImprimirRelatorioTurmasPorSerie from 'views/gestor/ImprimirRelatorioTurmasPorSerie';
import ImprimirRelatorioDiariosPorEscola from 'views/gestor/ImprimirRelatorioDiariosPorEscola';
import ImprimirRelatorioFuncionariosPorEscola from 'views/gestor/ImprimirRelatorioFuncionariosPorEscola';
import ImprimirRelatorioOcorrenciasPorEscola from 'views/gestor/ImprimirRelatorioOcorrenciasPorEscola';
import ImprimirRelatorioJustificativasFaltasPorTurma from 'views/gestor/ImprimirRelatorioJustificativasFaltasPorTurma';
import ImprimirRelatorioLivroMatriculaPorEscola from 'views/gestor/ImprimirRelatorioLivroMatriculaPorEscola';
import ImprimirRelatorioLivroMatriculaPorTurma from 'views/gestor/ImprimirRelatorioLivroMatriculaPorTurma';
import ImprimirRelatorioNotasFinaisPorTurma from 'views/gestor/ImprimirRelatorioNotasFinaisPorTurma';
import ImprimirRelatorioFichasIndividuaisPorTurma from 'views/gestor/ImprimirRelatorioFichasIndividuaisPorTurma';
import ImprimirRelatorioFrequenciasMensaisPorTurma from 'views/gestor/ImprimirRelatorioFrequenciasMensaisPorTurma';
import ImprimirRelatorioQuantidadeFrequenciaDia from 'views/gestor/ImprimirRelatorioQuantidadeFrequenciaDia';

import RelatoriosDistorcaoIdadeSerie from 'views/gestor/RelatoriosDistorcaoIdadeSerie';
import ImprimirRelatorioDistorcaoIdadeSeriePorEscola from 'views/gestor/ImprimirRelatorioDistorcaoIdadeSeriePorEscola';
import ImprimirRelatorioDistorcaoIdadeSerieNoMunicipio from 'views/gestor/ImprimirRelatorioDistorcaoIdadeSerieNoMunicipio';

import RelatoriosProfessores from 'views/gestor/RelatoriosProfessores';
import ImprimirRelatorioProfessoresPorTurma from 'views/gestor/ImprimirRelatorioProfessoresPorTurma';
import ImprimirRelatorioProfessoresPorEscola from 'views/gestor/ImprimirRelatorioProfessoresPorEscola';
import ImprimirRelatorioProfessores from 'views/gestor/ImprimirRelatorioProfessores';

import RelatoriosAlunos from 'views/gestor/RelatoriosAlunos';
import ImprimirRelatorioAlunosPorEscola from 'views/gestor/ImprimirRelatorioAlunosPorEscola';
import ImprimirRelatorioAlunosBolsaFamiliaPorEscola from 'views/gestor/ImprimirRelatorioAlunosBolsaFamiliaPorEscola';
import ImprimirRelatorioAlunosPorTurma from 'views/gestor/ImprimirRelatorioAlunosPorTurma';
import ImprimirRelatorioAlunosPnePorEscola from 'views/gestor/ImprimirRelatorioAlunosPnePorEscola';
import ImprimirRelatorioAlunosPnePorTurma from 'views/gestor/ImprimirRelatorioAlunosPnePorTurma';
import ImprimirRelatorioAlunosPorIdade from 'views/gestor/ImprimirRelatorioAlunosPorIdade';
import ImprimirRelatorioAlunosForaDeFaixaPorEscola from 'views/gestor/ImprimirRelatorioAlunosForaDeFaixaPorEscola';
import ImprimirRelatorioAlunosAbaixoDaMediaPorTurma from 'views/gestor/ImprimirRelatorioAlunosAbaixoDaMediaPorTurma';
//import ImprimirRelatorioAlunosBaixaFrequenciaPorTurma from 'views/gestor/ImprimirRelatorioAlunosBaixaFrequenciaPorTurma';
import ImprimirRelatorioHabilidadesAdquiridasAluno from 'views/gestor/ImprimirRelatorioHabilidadesAdquiridasAluno';
import ImprimirRelatorioHabilidadesAdquiridas from 'views/gestor/ImprimirRelatorioHabilidadesAdquiridas';
import ImprimirRelatorioNotasPorTurma from 'views/gestor/ImprimirRelatorioNotasPorTurma';
import ImprimirRelatorioMediasAlunosPorEscola from 'views/gestor/ImprimirRelatorioMediasAlunosPorEscola';
import ImprimirRelatorioNotasAlunosPorMateria from 'views/gestor/ImprimirRelatorioNotasAlunosPorMateria';
import ImprimirRelatorioNotasAlunosPorProfessor from 'views/gestor/ImprimirRelatorioNotasAlunosPorProfessor';
import ImprimirRelatorioNotasAlunosPorBimestre from 'views/gestor/ImprimirRelatorioNotasAlunosPorBimestre';
import ImprimirRelatorioFrequenciaAlunosPorMes from 'views/gestor/ImprimirRelatorioFrequenciaAlunosPorMes';
import ImprimirRelatorioFrequenciaAlunosPorBimestre from 'views/gestor/ImprimirRelatorioFrequenciaAlunosPorBimestre';
import ImprimirRelatorioFrequenciaAlunosPorMateria from 'views/gestor/ImprimirRelatorioFrequenciaAlunosPorMateria';
import ImprimirRelatorioContagemAlunos from 'views/gestor/ImprimirRelatorioContagemAlunos';
import ImprimirRelatorioContagemAlunosPorEscolaEtapa from 'views/gestor/ImprimirRelatorioContagemAlunosPorEscolaEtapa';
import ImprimirRelatorioContagemAlunosAprovadosReprovadosPorEscola from 'views/gestor/ImprimirRelatorioContagemAlunosAprovadosReprovadosPorEscola';
import ImprimirRelatorioFaltasAluno from 'views/gestor/ImprimirRelatorioFaltasAluno';
import ImprimirRelatorioPeiAluno from 'views/gestor/ImprimirRelatorioPeiAluno';
import ImprimirRelatorioAlunosPorTurnoPorEscola from 'views/gestor/ImprimirRelatorioAlunosPorTurnoPorEscola';
import ImprimirRelatorioAlunosMatriculadosDependenciaPorTurma from 'views/gestor/ImprimirRelatorioAlunosMatriculadosDependenciaPorTurma';
import ImprimirRelatorioAlunosAprovadosDependenciaPorTurma from 'views/gestor/ImprimirRelatorioAlunosAprovadosDependenciaPorTurma';
import ImprimirRelatorioFardamentoAlunosPorEscola from 'views/gestor/ImprimirRelatorioFardamentoAlunosPorEscola';

import RelatoriosRegistrosAula from 'views/gestor/RelatoriosRegistrosAula';
import ImprimirRelatorioRegistrosAulaPorEscola from 'views/gestor/ImprimirRelatorioRegistrosAulaPorEscola';
import ImprimirRelatorioRegistrosAulaPorTurma from 'views/gestor/ImprimirRelatorioRegistrosAulaPorTurma';
import ImprimirRelatorioRegistrosAulaPorProfessor from 'views/gestor/ImprimirRelatorioRegistrosAulaPorProfessor';
import ImprimirRelatorioQuantidadeRegistrosAulaPorEscola from 'views/gestor/ImprimirRelatorioQuantidadeRegistrosAulaPorEscola';
import ImprimirRelatorioQuantidadeRegistrosAulaPorTurma from 'views/gestor/ImprimirRelatorioQuantidadeRegistrosAulaPorTurma';
import ImprimirRelatorioQuantidadeRegistrosAulaPorProfessor from 'views/gestor/ImprimirRelatorioQuantidadeRegistrosAulaPorProfessor';
import ImprimirRelatorioDiariosRegistroAulasEmAtraso from 'views/gestor/ImprimirRelatorioDiariosRegistroAulasEmAtraso';

import RelatoriosEstoques from 'views/gestor/RelatoriosEstoques';
import ImprimirRelatorioEntradasEstoquePorEscola from 'views/gestor/ImprimirRelatorioEntradasEstoquePorEscola';
import ImprimirRelatorioSaidasEstoquePorEscola from 'views/gestor/ImprimirRelatorioSaidasEstoquePorEscola';

import DadosEnte from 'views/gestor/DadosEnte';
import Escolas from 'views/gestor/Escolas';
import Cursos from 'views/gestor/Cursos';
import Curso from 'views/gestor/Curso';
import CadastroEstruturaCurricular from 'views/gestor/CadastroEstruturaCurricular';
import EstruturaCurricular from 'views/gestor/EstruturaCurricular';
import ItensAtelie from 'views/gestor/ItensAtelie';
import Competencias from 'views/gestor/Competencias';
import CompetenciasMunicipais from 'views/gestor/CompetenciasMunicipais';
import ObjetivosAprendizagem from 'views/gestor/ObjetivosAprendizagem';
import CadastroEscola from 'views/gestor/CadastroEscola';
import Escola from 'views/gestor/Escola';
import CadastroSolicitacaoTransferencia from 'views/gestor/CadastroSolicitacaoTransferencia';
import CadastroDiretor from 'views/gestor/CadastroDiretor';
import CadastroSecretario from 'views/gestor/CadastroSecretario';
import CadastroCoordenador from 'views/gestor/CadastroCoordenador';
import CadastroItemMerenda from 'views/gestor/CadastroItemMerenda';
import CadastroFuncionario from 'views/gestor/CadastroFuncionario';
import Funcionario from 'views/gestor/Funcionario';
import AnosLetivos from 'views/gestor/AnosLetivos';
import CadastroAnoLetivo from 'views/gestor/CadastroAnoLetivo';
import AnoLetivo from 'views/gestor/AnoLetivo';
import CadastroCargaHoraria from 'views/gestor/CadastroCargaHoraria';
import Transportes from 'views/gestor/Transportes';
import CadastroTransporte from 'views/gestor/CadastroTransporte';
import Transporte from 'views/gestor/Transporte';
import Afastamentos from 'views/gestor/Afastamentos';
import CadastroAfastamento from 'views/gestor/CadastroAfastamento';
import Afastamento from 'views/gestor/Afastamento';
import CadastroOcorrencia from 'views/gestor/CadastroOcorrencia';
import Ocorrencia from 'views/gestor/Ocorrencia';
import AdicionarUsuarioTransporte from 'views/gestor/AdicionarUsuarioTransporte';
import Estoques from 'views/gestor/Estoques';
import CadastroEstoque from 'views/gestor/CadastroEstoque';
import Estoque from 'views/gestor/Estoque';
import CadastroEstoqueItem from 'views/gestor/CadastroEstoqueItem';
import EstoqueItem from 'views/gestor/EstoqueItem';
import CadastroEstoqueEntrada from 'views/gestor/CadastroEstoqueEntrada';
import EstoqueEntrada from 'views/gestor/EstoqueEntrada';
import CadastroEstoqueSaida from 'views/gestor/CadastroEstoqueSaida';
import EstoqueSaida from 'views/gestor/EstoqueSaida';
import Convites from 'views/gestor/ConvitesDiretoresProfessores';
import Servidores from 'views/gestor/Servidores';
import CadastroCurso from 'views/gestor/CadastroCurso';
import TurmasEscola from 'views/gestor/TurmasEscola';
import ImprimirHistorico from 'views/gestor/ImprimirHistorico';
import ImprimirHistoricoResumido from 'views/gestor/ImprimirHistoricoResumido';
import ImprimirDeclaracaoMatricula from 'views/gestor/ImprimirDeclaracaoMatricula';
import ImprimirDeclaracaoConclusao from 'views/gestor/ImprimirDeclaracaoConclusao';
import ImprimirDeclaracaoConclusaoSerie from 'views/gestor/ImprimirDeclaracaoConclusaoSerie';
import ImprimirDeclaracaoFrequenciaMensal from 'views/gestor/ImprimirDeclaracaoFrequenciaMensal';
import ImprimirDeclaracaoTransferencia from 'views/gestor/ImprimirDeclaracaoTransferencia';
import ImprimirDeclaracaoGeral from 'views/gestor/ImprimirDeclaracaoGeral';
import ImprimirBoletimAluno from 'views/gestor/ImprimirBoletimAluno';
import ImprimirBoletinsPorTurma from 'views/gestor/ImprimirBoletinsPorTurma';
import ImprimirFichaMatricula from 'views/gestor/ImprimirFichaMatricula';
import ImprimirFichaIndividual from 'views/gestor/ImprimirFichaIndividual';
import ImprimirFichasIndividuaisPorTurma from 'views/gestor/ImprimirFichasIndividuaisPorTurma';
import ImprimirDeclaracaoVagasEscola from 'views/gestor/ImprimirDeclaracaoVagasEscola';
import CadastroTurma from 'views/gestor/CadastroTurma';
import CadastroTurmaMultiseriado from 'views/gestor/CadastroTurmaMultiseriado';
import Turma from 'views/gestor/Turma';
import TurmaMultiseriado from 'views/gestor/TurmaMultiseriado';
import MatriculaAluno from 'views/gestor/MatriculaAluno';
import MudarSituacaoAluno from 'views/gestor/MudarSituacaoAluno';
import Alunos from 'views/gestor/Alunos';
import PreCadastroAluno from 'views/gestor/PreCadastroAluno';
import CadastroAluno from 'views/gestor/CadastroAluno';
import HistoricoAluno from 'views/gestor/HistoricoAluno';
import FrequenciaDiario from 'views/gestor/FrequenciaDiario';
import CadastroHistorico from 'views/gestor/CadastroHistorico';
import Pais from 'views/gestor/Pais';
import EducaCenso from 'views/gestor/EducaCenso';
import Depoimentos from 'views/comum/Depoimentos';
import CadastroDepoimento from 'views/comum/CadastroDepoimento';
// import Planos from 'views/gestor/Planos';
// import AssinarPlano from 'views/gestor/AssinarPlano';
import LimitesEnte from 'views/gestor/LimitesEnte';
import ContatoLogado from 'views/comum/ContatoLogado';
import RelatorioContagemGeral from 'views/gestor/RelatorioContagemGeral';
import RelatorioSituacaoTurmas from 'views/gestor/RelatorioSituacaoTurmas';
import RelatorioSituacaoDiarios from 'views/gestor/RelatorioSituacaoDiarios';
import RelatorioInformacoesMatriculas from 'views/gestor/RelatorioInformacoesMatriculas';
import RelatorioMatriculasPorSerie from 'views/gestor/RelatorioMatriculasPorSerie';
import RelatorioTurmasPorSerie from 'views/gestor/RelatorioTurmasPorSerie';
import RelatorioDistorcaoIdadeSerie from 'views/gestor/RelatorioDistorcaoIdadeSerie';
import RelatorioMatriculasPorSexo from 'views/gestor/RelatorioMatriculasPorSexo';
import RelatorioMatriculasPorEtnia from 'views/gestor/RelatorioMatriculasPorEtnia';
import RelatorioTaxaEvasao from 'views/gestor/RelatorioTaxaEvasao';
import Chamados from 'views/gestor/Chamados';
import CadastroChamado from 'views/gestor/CadastroChamado';
import Chamado from 'views/gestor/Chamado';
import Ouvidoria from 'views/gestor/Ouvidoria';
import CadastroChamadoOuvidoria from 'views/gestor/CadastroChamadoOuvidoria';
import ChamadoOuvidoria from 'views/gestor/ChamadoOuvidoria';

import GerenciaDiarios from 'views/gestor/GerenciaDiarios';
import Diario from 'views/professor/Diario';
import DiarioAlfabetizacao from 'views/professor/DiarioAlfabetizacao';
import DiarioTurmaMultiseriado from 'views/professor/DiarioTurmaMultiseriado';
import Notas from 'views/professor/Notas';
import Relatorios from 'views/professor/Relatorios';
import Atelie from 'views/professor/Atelie';
import RelatorioAluno from 'views/professor/RelatorioAluno';
import RegistrosDiarioAluno from 'views/professor/RegistrosDiarioAluno';
import CadastroRegistroDiarioAluno from 'views/professor/CadastroRegistroDiarioAluno';
import RegistroDiarioAluno from 'views/professor/RegistroDiarioAluno';
import AvaliacaoEspecialAluno from 'views/professor/AvaliacaoEspecialAluno';
import AvaliacaoConceitual from 'views/professor/AvaliacaoConceitual';
import ConteudoLecionado from 'views/professor/ConteudoLecionado';
import ConteudoLecionadoTurmaMultiseriado from 'views/professor/ConteudoLecionadoTurmaMultiseriado';
import HabilidadesTrabalhadas from 'views/professor/HabilidadesTrabalhadas';
import HabilidadesAdquiridasAluno from 'views/professor/HabilidadesAdquiridasAluno';
import HabilidadesAdquiridasAlunoBimestres from 'views/professor/HabilidadesAdquiridasAlunoBimestres';
import CompetenciasMunicipaisAdquiridasAluno from 'views/professor/CompetenciasMunicipaisAdquiridasAluno';
import ObjetivosAprendizagemTrabalhados from 'views/professor/ObjetivosAprendizagemTrabalhados';
import ObjetivosAprendizagemAdquiridosAluno from 'views/professor/ObjetivosAprendizagemAdquiridosAluno';
import ObjetivosAprendizagemOuHabilidadesTrabalhadasMultiseriado from 'views/professor/ObjetivosAprendizagemOuHabilidadesTrabalhadasMultiseriado';
import ObjetivosAprendizagemOuHabilidadesAdquiridasAlunoMultiseriado from 'views/professor/ObjetivosAprendizagemOuHabilidadesAdquiridasAlunoMultiseriado';
import RelatorioTurma from 'views/professor/RelatorioTurma';
import RelatorioTurmaMultiseriado from 'views/professor/RelatorioTurmaMultiseriado';
import CalendarioFrequencia from 'views/professor/CalendarioFrequencia';
import CalendarioFrequenciaMultiseriado from 'views/professor/CalendarioFrequenciaMultiseriado';
import MesFrequencia from 'views/professor/MesFrequencia';
import MesFrequenciaMultiseriado from 'views/professor/MesFrequenciaMultiseriado';
import JustificativaFaltas from 'views/professor/JustificativaFaltas';
import JustificativaFaltasMultiseriado from 'views/professor/JustificativaFaltasMultiseriado';
import Abono from 'views/professor/Abono';
import AbonoMultiseriado from 'views/professor/AbonoMultiseriado';
import Frequencia from 'views/professor/Frequencia';
import FrequenciaMultiseriado from 'views/professor/FrequenciaMultiseriado';
import TurmaVirtual from 'views/professor/TurmaVirtual';
import Aula from 'views/professor/Aula';
import Tarefa from 'views/professor/Tarefa';
import EnviosAluno from 'views/professor/EnviosAluno';
import AtaFinal from 'views/professor/AtaFinal';
import AtaFinalAlfabetizacao from 'views/professor/AtaFinalAlfabetizacao';
import AtaFinalTurmaMultiseriado from 'views/professor/AtaFinalTurmaMultiseriado';
import RegistroAulas from 'views/professor/RegistroAulas';
import RegistroAulasMultiseriado from 'views/professor/RegistroAulasMultiseriado';
import RegistroAula from 'views/professor/RegistroAula';
import MatriculasOnline from 'views/gestor/MatriculasOnline';
import SolicitacaoMatricula from 'views/gestor/SolicitacaoMatricula';
import AulasProfessores from 'views/gestor/AulasProfessores';
import ListagemAulasProfessor from 'views/gestor/ListagemAulasProfessor';
import AulaProfessor from 'views/gestor/AulaProfessor';
import Tutoriais from 'views/gestor/Tutoriais';
import AgendaDiario from 'views/professor/AgendaDiario';
import AgendaDiarioMultiseriado from 'views/professor/AgendaDiarioMultiseriado';

import DadosUsuario from 'views/comum/DadosUsuario';
import MudarSenha from 'views/comum/MudarSenha';

import Papel from 'assets/csv/papeis.json';

const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'fa fa-line-chart',
    component: DashboardGestor,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/noticias',
    name: 'Notícias',
    icon: 'far fa-newspaper',
    component: Noticias,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/noticias/cadastro',
    component: CadastroNoticia,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/noticias/edicao/:idNoticia',
    component: CadastroNoticia,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/noticias/:idNoticia',
    component: Noticia,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/documentos',
    name: 'Documentos',
    icon: 'fas fa-file-pdf',
    component: Documentos,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/documentos/historicos',
    component: ImprimirHistorico,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/documentos/historicos/resumidos',
    component: ImprimirHistoricoResumido,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/documentos/declaracao/matricula',
    component: ImprimirDeclaracaoMatricula,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/documentos/declaracao/conclusao',
    component: ImprimirDeclaracaoConclusao,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/documentos/declaracao/conclusao/serie',
    component: ImprimirDeclaracaoConclusaoSerie,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/documentos/declaracao/frequencia/mensal',
    component: ImprimirDeclaracaoFrequenciaMensal,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/documentos/declaracao/transferencia',
    component: ImprimirDeclaracaoTransferencia,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/documentos/declaracao/geral',
    component: ImprimirDeclaracaoGeral,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/documentos/boletim/aluno',
    component: ImprimirBoletimAluno,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/documentos/boletins/turma',
    component: ImprimirBoletinsPorTurma,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/documentos/ficha/matricula',
    component: ImprimirFichaMatricula,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/documentos/ficha/individual',
    component: ImprimirFichaIndividual,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/documentos/fichas/individuais/turma',
    component: ImprimirFichasIndividuaisPorTurma,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/documentos/declaracao/vagas',
    component: ImprimirDeclaracaoVagasEscola,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios',
    name: 'Relatórios',
    icon: 'fas fa-file-pdf',
    component: RelatoriosEstatisticos,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/transportes/passageirosportransporte',
    component: ImprimirRelatorioPassageirosPorTransporte,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/transportes/passageirosporturma',
    component: ImprimirRelatorioPassageirosPorTurma,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/matriculasnaorenovadas',
    component: ImprimirRelatorioMatriculasNaoRenovadas,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/datasanoletivo',
    component: ImprimirRelatorioDatasAnoLetivo,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/escolasturmas',
    component: RelatoriosEscolasTurmas,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/transportes',
    component: RelatoriosTransportes,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/escolasturmas/turmasporescola',
    component: ImprimirRelatorioTurmasPorEscola,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/escolasturmas/listagemrelatoriosturmasnaoinformadosporescola',
    component: ImprimirListagemRelatoriosTurmasNaoInformadosPorEscola,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/escolasturmas/turmasporserie',
    component: ImprimirRelatorioTurmasPorSerie,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/escolasturmas/diariosporescola',
    component: ImprimirRelatorioDiariosPorEscola,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/escolasturmas/funcionariosporescola',
    component: ImprimirRelatorioFuncionariosPorEscola,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/escolasturmas/ocorrenciasporescola',
    component: ImprimirRelatorioOcorrenciasPorEscola,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/escolasturmas/justificativasfaltasporturma',
    component: ImprimirRelatorioJustificativasFaltasPorTurma,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/escolasturmas/livromatriculaporescola',
    component: ImprimirRelatorioLivroMatriculaPorEscola,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/escolasturmas/livromatriculaporturma',
    component: ImprimirRelatorioLivroMatriculaPorTurma,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/escolasturmas/notasfinaisporturma',
    component: ImprimirRelatorioNotasFinaisPorTurma,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/escolasturmas/fichasindividuaisporturma',
    component: ImprimirRelatorioFichasIndividuaisPorTurma,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/escolasturmas/frequenciasmensaisporturma',
    component: ImprimirRelatorioFrequenciasMensaisPorTurma,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/escolasturmas/quantidadefrequenciadia',
    component: ImprimirRelatorioQuantidadeFrequenciaDia,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/distorcaoidadeserie',
    component: RelatoriosDistorcaoIdadeSerie,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/distorcaoidadeserie/porescola',
    component: ImprimirRelatorioDistorcaoIdadeSeriePorEscola,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/distorcaoidadeserie/pormunicipio',
    component: ImprimirRelatorioDistorcaoIdadeSerieNoMunicipio,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/professores',
    component: RelatoriosProfessores,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/professores/porturma',
    component: ImprimirRelatorioProfessoresPorTurma,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/professores/porescola',
    component: ImprimirRelatorioProfessoresPorEscola,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/professores/cadastrados',
    component: ImprimirRelatorioProfessores,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/alunos',
    component: RelatoriosAlunos,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/alunos/porescola',
    component: ImprimirRelatorioAlunosPorEscola,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/alunosbolsafamilia/porescola',
    component: ImprimirRelatorioAlunosBolsaFamiliaPorEscola,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/alunos/porturma',
    component: ImprimirRelatorioAlunosPorTurma,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/alunos/pneporescola',
    component: ImprimirRelatorioAlunosPnePorEscola,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/alunos/pneporturma',
    component: ImprimirRelatorioAlunosPnePorTurma,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/alunos/poridade',
    component: ImprimirRelatorioAlunosPorIdade,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/alunos/foradefaixaporescola',
    component: ImprimirRelatorioAlunosForaDeFaixaPorEscola,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/alunos/abaixodamediaporturma',
    component: ImprimirRelatorioAlunosAbaixoDaMediaPorTurma,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/alunos/habilidadesadquiridasporaluno',
    component: ImprimirRelatorioHabilidadesAdquiridasAluno,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/alunos/habilidadesadquiridasporturma',
    component: ImprimirRelatorioHabilidadesAdquiridas,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/alunos/notasporturma',
    component: ImprimirRelatorioNotasPorTurma,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/alunos/mediasporescola',
    component: ImprimirRelatorioMediasAlunosPorEscola,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/alunos/notaspormateria',
    component: ImprimirRelatorioNotasAlunosPorMateria,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/alunos/notasporprofessor',
    component: ImprimirRelatorioNotasAlunosPorProfessor,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/alunos/notasporbimestre',
    component: ImprimirRelatorioNotasAlunosPorBimestre,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/alunos/frequenciapormes',
    component: ImprimirRelatorioFrequenciaAlunosPorMes,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/alunos/frequenciaporbimestre',
    component: ImprimirRelatorioFrequenciaAlunosPorBimestre,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/alunos/frequenciapormateria',
    component: ImprimirRelatorioFrequenciaAlunosPorMateria,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/alunos/contagemporescola',
    component: ImprimirRelatorioContagemAlunos,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/alunos/contagemporescolaetapa',
    component: ImprimirRelatorioContagemAlunosPorEscolaEtapa,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/alunos/percentualaprovadoreprovadoporescola',
    component: ImprimirRelatorioContagemAlunosAprovadosReprovadosPorEscola,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/alunos/faltas',
    component: ImprimirRelatorioFaltasAluno,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/alunos/pei',
    component: ImprimirRelatorioPeiAluno,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/alunos/turno',
    component: ImprimirRelatorioAlunosPorTurnoPorEscola,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/alunos/matriculados/dependencia',
    component: ImprimirRelatorioAlunosMatriculadosDependenciaPorTurma,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/alunos/aprovados/dependencia',
    component: ImprimirRelatorioAlunosAprovadosDependenciaPorTurma,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/fardamentoalunos/porescola',
    component: ImprimirRelatorioFardamentoAlunosPorEscola,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/registrosaula',
    component: RelatoriosRegistrosAula,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/registrosaula/porescola',
    component: ImprimirRelatorioRegistrosAulaPorEscola,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/registrosaula/porturma',
    component: ImprimirRelatorioRegistrosAulaPorTurma,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/registrosaula/porprofessor',
    component: ImprimirRelatorioRegistrosAulaPorProfessor,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/quantidaderegistrosaula/porescola',
    component: ImprimirRelatorioQuantidadeRegistrosAulaPorEscola,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/quantidaderegistrosaula/porturma',
    component: ImprimirRelatorioQuantidadeRegistrosAulaPorTurma,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/quantidaderegistrosaula/porprofessor',
    component: ImprimirRelatorioQuantidadeRegistrosAulaPorProfessor,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/diariosregistroaulasematraso/porescola',
    component: ImprimirRelatorioDiariosRegistroAulasEmAtraso,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/estoques',
    component: RelatoriosEstoques,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/estoques/saidasporescola',
    component: ImprimirRelatorioSaidasEstoquePorEscola,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/relatorios/estoques/entradasporescola',
    component: ImprimirRelatorioEntradasEstoquePorEscola,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/ente',
    name: 'Dados do Ente',
    icon: 'fas fa-file-alt',
    component: DadosEnte,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/escolas',
    name: 'Escolas',
    icon: 'fas fa-school',
    component: Escolas,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/escolas/cadastro',
    component: CadastroEscola,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/escolas/edicao/:idEscola',
    component: CadastroEscola,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/escolas/:idEscola',
    component: Escola,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/escolas/:idEscola/cadastro/diretor',
    component: CadastroDiretor,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/escolas/:idEscola/cadastro/secretario',
    component: CadastroSecretario,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/escolas/:idEscola/cadastro/coordenador',
    component: CadastroCoordenador,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/escolas/:idEscola/itemmerenda/cadastro',
    component: CadastroItemMerenda,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/escolas/:idEscola/itemmerenda/edicao/:idItemMerenda',
    component: CadastroItemMerenda,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/escolas/:idEscola/funcionario/cadastro',
    component: CadastroFuncionario,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/escolas/:idEscola/funcionario/edicao/:idFuncionario',
    component: CadastroFuncionario,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/escolas/:idEscola/funcionario/:idFuncionario',
    component: Funcionario,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/escolas/:idEscola/solicitar/transferencia',
    component: CadastroSolicitacaoTransferencia,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/escolas/:idEscola/estoques/:idEstoque',
    component: Estoque,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/cursos',
    name: 'Cursos',
    icon: 'fa fa-graduation-cap',
    component: Cursos,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/cursos/cadastro',
    component: CadastroCurso,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/cursos/edicao/:idCurso',
    component: CadastroCurso,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/cursos/:idCurso',
    component: Curso,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/cursos/:idCurso/estruturascurriculares/cadastro',
    component: CadastroEstruturaCurricular,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/cursos/:idCurso/estruturascurriculares/edicao/:idEstruturaCurricular',
    component: CadastroEstruturaCurricular,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/cursos/:idCurso/estruturascurriculares/:idEstruturaCurricular',
    component: EstruturaCurricular,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/cursos/:idCurso/estruturascurriculares/:idEstruturaCurricular/itensatelie/materia/:idMateria',
    component: ItensAtelie,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/cursos/:idCurso/estruturascurriculares/:idEstruturaCurricular/competencias/materia/:idMateria',
    component: Competencias,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/cursos/:idCurso/estruturascurriculares/:idEstruturaCurricular/competencias/municipais/materia/:idMateria',
    component: CompetenciasMunicipais,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/cursos/:idCurso/estruturascurriculares/:idEstruturaCurricular/objetivos/materia/:idMateria',
    component: ObjetivosAprendizagem,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos',
    name: 'Anos Letivos',
    icon: 'fa fa-calendar',
    component: AnosLetivos,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/cadastro',
    component: CadastroAnoLetivo,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/edicao/:idAnoLetivo',
    component: CadastroAnoLetivo,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo',
    component: AnoLetivo,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/cargahoraria/cadastro',
    component: CadastroCargaHoraria,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/cargahoraria/edicao/:idCargaHoraria',
    component: CadastroCargaHoraria,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola',
    component: TurmasEscola,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turmas/cadastro',
    component: CadastroTurma,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turmas/edicao/:idTurma',
    component: CadastroTurma,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turmas/multiseriado/cadastro',
    component: CadastroTurmaMultiseriado,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turmas/multiseriado/edicao/:idTurma',
    component: CadastroTurmaMultiseriado,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma',
    component: Turma,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma',
    component: TurmaMultiseriado,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/matricula/aluno',
    component: MatriculaAluno,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/matricula/aluno/edicao/:idMatricula',
    component: MatriculaAluno,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/situacao/:idMatricula',
    component: MudarSituacaoAluno,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/frequencia/ano',
    component: FrequenciaDiario,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/transporte/:idTransporte',
    component: Transporte,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/transporte/:idTransporte/adicionarpassageiro',
    component: AdicionarUsuarioTransporte,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/ocorrencias/cadastro',
    component: CadastroOcorrencia,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/ocorrencias/edicao/:idOcorrencia',
    component: CadastroOcorrencia,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/ocorrencia/:idOcorrencia',
    component: Ocorrencia,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/estoques',
    name: 'Estoques',
    icon: 'fas fa-dolly-flatbed-alt',
    component: Estoques,
    layout: '/gestor',
    role: Papel.GESTOR.value,
    controleEstoque: true,
  },
  {
    path: '/estoques/cadastro',
    component: CadastroEstoque,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/estoques/edicao/:idEstoque',
    component: CadastroEstoque,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/estoques/:idEstoque',
    component: Estoque,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/estoques/:idEstoque/itens/cadastro',
    component: CadastroEstoqueItem,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/estoques/:idEstoque/itens/edicao/:idEstoqueItem',
    component: CadastroEstoqueItem,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/estoques/:idEstoque/itens/:idEstoqueItem',
    component: EstoqueItem,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/estoques/:idEstoque/entradas/cadastro',
    component: CadastroEstoqueEntrada,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/estoques/:idEstoque/entradas/edicao/:idEstoqueEntrada',
    component: CadastroEstoqueEntrada,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/estoques/:idEstoque/entradas/:idEstoqueEntrada',
    component: EstoqueEntrada,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/estoques/:idEstoque/saidas/cadastro',
    component: CadastroEstoqueSaida,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/estoques/:idEstoque/saidas/edicao/:idEstoqueSaida',
    component: CadastroEstoqueSaida,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/estoques/:idEstoque/saidas/:idEstoqueSaida',
    component: EstoqueSaida,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/escolas/:idEscola/estoques/:idEstoque',
    component: Estoque,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/escolas/:idEscola/estoques/:idEstoque/itens/cadastro',
    component: CadastroEstoqueItem,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/escolas/:idEscola/estoques/:idEstoque/itens/edicao/:idEstoqueItem',
    component: CadastroEstoqueItem,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/escolas/:idEscola/estoques/:idEstoque/itens/:idEstoqueItem',
    component: EstoqueItem,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/escolas/:idEscola/estoques/:idEstoque/entradas/cadastro',
    component: CadastroEstoqueEntrada,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/escolas/:idEscola/estoques/:idEstoque/entradas/edicao/:idEstoqueEntrada',
    component: CadastroEstoqueEntrada,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/escolas/:idEscola/estoques/:idEstoque/entradas/:idEstoqueEntrada',
    component: EstoqueEntrada,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/escolas/:idEscola/estoques/:idEstoque/saidas/cadastro',
    component: CadastroEstoqueSaida,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/escolas/:idEscola/estoques/:idEstoque/saidas/edicao/:idEstoqueSaida',
    component: CadastroEstoqueSaida,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/escolas/:idEscola/estoques/:idEstoque/saidas/:idEstoqueSaida',
    component: EstoqueSaida,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/convites',
    name: 'Convites',
    icon: 'fa fa-envelope-o',
    component: Convites,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/servidores',
    name: 'Servidores',
    icon: 'fa fa-users',
    component: Servidores,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/servidores/:categoria/:idUsuario',
    component: DadosUsuario,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/alunos',
    name: 'Alunos',
    icon: 'fa fa-users',
    component: Alunos,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/alunos/precadastro',
    component: PreCadastroAluno,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/alunos/cadastro',
    component: CadastroAluno,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/alunos/edicao/:idAluno',
    component: CadastroAluno,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/alunos/historico/:idAluno',
    component: HistoricoAluno,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/alunos/historico/:idAluno/cadastro',
    component: CadastroHistorico,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/alunos/historico/:idAluno/edicao/:idHistorico',
    component: CadastroHistorico,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/pais',
    name: 'Pais',
    icon: 'fa fa-user-friends',
    component: Pais,
    layout: '/gestor',
    role: Papel.GESTOR.value,
    acessoPais: true,
  },
  {
    path: '/pais/:idUsuario',
    component: DadosUsuario,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
    acessoPais: true,
  },
  {
    path: '/educacenso',
    name: 'Educacenso',
    icon: 'fas fa-table',
    component: EducaCenso,
    layout: '/gestor',
    role: Papel.GESTOR.value,
    educaCenso: true,
  },
  {
    path: '/transportes',
    name: 'Rotas Transportes',
    icon: 'fa fa-bus',
    component: Transportes,
    layout: '/gestor',
    role: Papel.GESTOR.value,
    transportes: true,
  },
  {
    path: '/transportes/cadastro',
    component: CadastroTransporte,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/transportes/edicao/:idTransporte',
    component: CadastroTransporte,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/afastamentos',
    name: 'Afastamentos',
    icon: 'fas fa-user-alt',
    component: Afastamentos,
    layout: '/gestor',
    role: Papel.GESTOR.value,
    afastamentos: true,
  },
  {
    path: '/afastamentos/cadastro',
    component: CadastroAfastamento,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/afastamentos/edicao/:idAfastamento',
    component: CadastroAfastamento,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/afastamentos/:idAfastamento',
    component: Afastamento,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/depoimentos',
    component: Depoimentos,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/depoimentos/cadastro',
    component: CadastroDepoimento,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/depoimentos/:idDepoimento',
    component: CadastroDepoimento,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  /* {
    path: '/planos',
    name: 'Planos',
    icon: 'fa fa-arrow-circle-up',
    component: Planos,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/planos/assinar/:idPlano',
    component: AssinarPlano,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },*/
  {
    path: '/limites',
    component: LimitesEnte,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/contato',
    component: ContatoLogado,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/dashboard/relatorios/contagemgeral/anoletivo/:idAnoLetivo',
    component: RelatorioContagemGeral,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/dashboard/relatorios/contagemgeral/anoletivo/:idAnoLetivo/escola/:idEscola',
    component: RelatorioContagemGeral,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/dashboard/relatorios/situacaoturmas/anoletivo/:idAnoLetivo',
    component: RelatorioSituacaoTurmas,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/dashboard/relatorios/situacaoturmas/anoletivo/:idAnoLetivo/escola/:idEscola',
    component: RelatorioSituacaoTurmas,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/dashboard/relatorios/situacaodiarios/anoletivo/:idAnoLetivo',
    component: RelatorioSituacaoDiarios,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/dashboard/relatorios/situacaodiarios/anoletivo/:idAnoLetivo/escola/:idEscola',
    component: RelatorioSituacaoDiarios,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/dashboard/relatorios/informacoesmatriculas/anoletivo/:idAnoLetivo',
    component: RelatorioInformacoesMatriculas,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/dashboard/relatorios/informacoesmatriculas/anoletivo/:idAnoLetivo/escola/:idEscola',
    component: RelatorioInformacoesMatriculas,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/dashboard/relatorios/matriculasserie/anoletivo/:idAnoLetivo',
    component: RelatorioMatriculasPorSerie,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/dashboard/relatorios/matriculasserie/anoletivo/:idAnoLetivo/escola/:idEscola',
    component: RelatorioMatriculasPorSerie,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/dashboard/relatorios/turmasserie/anoletivo/:idAnoLetivo',
    component: RelatorioTurmasPorSerie,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/dashboard/relatorios/turmasserie/anoletivo/:idAnoLetivo/escola/:idEscola',
    component: RelatorioTurmasPorSerie,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/dashboard/relatorios/distorcaoidadeserie/anoletivo/:idAnoLetivo',
    component: RelatorioDistorcaoIdadeSerie,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/dashboard/relatorios/distorcaoidadeserie/anoletivo/:idAnoLetivo/escola/:idEscola',
    component: RelatorioDistorcaoIdadeSerie,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/dashboard/relatorios/matriculasporsexo/anoletivo/:idAnoLetivo',
    component: RelatorioMatriculasPorSexo,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/dashboard/relatorios/matriculasporsexo/anoletivo/:idAnoLetivo/escola/:idEscola',
    component: RelatorioMatriculasPorSexo,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/dashboard/relatorios/matriculasporetnia/anoletivo/:idAnoLetivo',
    component: RelatorioMatriculasPorEtnia,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/dashboard/relatorios/matriculasporetnia/anoletivo/:idAnoLetivo/escola/:idEscola',
    component: RelatorioMatriculasPorEtnia,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/dashboard/relatorios/taxaevasao/anoletivo/:idAnoLetivo',
    component: RelatorioTaxaEvasao,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/dashboard/relatorios/taxaevasao/anoletivo/:idAnoLetivo/escola/:idEscola',
    component: RelatorioTaxaEvasao,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },

  // diários
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/gerenciadiarios',
    component: GerenciaDiarios,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario',
    component: Diario,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/alfabetizacao',
    component: DiarioAlfabetizacao,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario',
    component: DiarioTurmaMultiseriado,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/notas/:idMateria',
    component: Notas,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/:idDiario/notas/:idMateria',
    component: Notas,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/atelie/:idMatricula',
    component: Atelie,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/:idDiario/atelie/:idMatricula',
    component: Atelie,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/relatorios/turma',
    component: RelatorioTurma,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/relatorios/turma',
    component: RelatorioTurmaMultiseriado,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/relatorios/:idMateria',
    component: Relatorios,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/relatorio/aluno/:idAluno',
    component: RelatorioAluno,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/alfabetizacao/registrosdiario/matricula/:idMatricula',
    component: RegistrosDiarioAluno,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/alfabetizacao/registrosdiario/matricula/:idMatricula/cadastro',
    component: CadastroRegistroDiarioAluno,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/alfabetizacao/registrosdiario/matricula/:idMatricula/edicao/:idRegistroDiarioAluno',
    component: CadastroRegistroDiarioAluno,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/alfabetizacao/registrosdiario/:idRegistroDiarioAluno',
    component: RegistroDiarioAluno,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/avaliacao/especial/matricula/:idMatricula',
    component: AvaliacaoEspecialAluno,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/avaliacao/conceitual/materia/:idMateria',
    component: AvaliacaoConceitual,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/conteudo/:idMateria',
    component: ConteudoLecionado,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/conteudo',
    component: ConteudoLecionadoTurmaMultiseriado,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/conteudo',
    component: ConteudoLecionado,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/habilidades',
    component: HabilidadesTrabalhadas,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/habilidades/matricula/:idMatricula',
    component: HabilidadesAdquiridasAluno,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/habilidades/matricula/:idMatricula/bimestres',
    component: HabilidadesAdquiridasAlunoBimestres,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/competencias/municipais/matricula/:idMatricula',
    component: CompetenciasMunicipaisAdquiridasAluno,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/objetivos',
    component: ObjetivosAprendizagemTrabalhados,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/objetivos/matricula/:idMatricula',
    component: ObjetivosAprendizagemAdquiridosAluno,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/objetivos/habilidades',
    component: ObjetivosAprendizagemOuHabilidadesTrabalhadasMultiseriado,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/objetivos/habilidades/matricula/:idMatricula',
    component: ObjetivosAprendizagemOuHabilidadesAdquiridasAlunoMultiseriado,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/frequencia',
    component: CalendarioFrequencia,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/frequencia',
    component: CalendarioFrequenciaMultiseriado,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/frequencia/mes',
    component: MesFrequencia,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/frequencia/mes',
    component: MesFrequenciaMultiseriado,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/frequencia/justificativa/faltas',
    component: JustificativaFaltas,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/frequencia/justificativa/faltas',
    component: JustificativaFaltasMultiseriado,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/frequencia/abono',
    component: Abono,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/frequencia/abono',
    component: AbonoMultiseriado,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/frequencia/data/:data',
    component: Frequencia,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/frequencia/data/:data',
    component: FrequenciaMultiseriado,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/materia/:idMateria/turmavirtual',
    component: TurmaVirtual,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/materia/:idMateria/turmavirtual/aula/:idAula',
    component: Aula,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/materia/:idMateria/turmavirtual/tarefa/:idTarefa',
    component: Tarefa,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/materia/:idMateria/turmavirtual/tarefa/:idTarefa/envios/aluno/:idAluno',
    component: EnviosAluno,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/atafinal',
    component: AtaFinal,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/alfabetizacao/:idDiario/atafinal',
    component: AtaFinalAlfabetizacao,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/atafinal',
    component: AtaFinalTurmaMultiseriado,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/chamados',
    component: Chamados,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/chamados/cadastro',
    component: CadastroChamado,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/chamados/:idChamado',
    component: Chamado,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/chamados/edicao/:idChamado',
    component: CadastroChamado,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/ouvidoria/chamados',
    component: Ouvidoria,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/ouvidoria/chamados/cadastrar',
    component: CadastroChamadoOuvidoria,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/ouvidoria/chamados/:idChamadoOuvidoria/editar',
    component: CadastroChamadoOuvidoria,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/ouvidoria/chamados/:idChamadoOuvidoria',
    component: ChamadoOuvidoria,
    layout: '/gestor',
    redirect: true,
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/registroaulas',
    component: RegistroAulas,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/registroaulas',
    component: RegistroAulasMultiseriado,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/registroaulas/:idRegistroAula',
    component: RegistroAula,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/matriculasonline',
    name: 'Matrículas Online',
    icon: 'fas fa-address-card',
    component: MatriculasOnline,
    layout: '/gestor',
    role: Papel.GESTOR.value,
    matriculasOnline: true,
  },
  {
    path: '/matriculasonline/solicitacaomatricula/:idSolicitacaoMatricula',
    component: SolicitacaoMatricula,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/aulasprofessores',
    name: 'Aulas Virtuais',
    icon: 'fas fa-tablet-alt',
    component: AulasProfessores,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/aulasprofessores/professor/:idProfessor/listagemaulas',
    component: ListagemAulasProfessor,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/aulasprofessores/professor/:idProfessor/listagemaulas/aula/:idAula',
    component: AulaProfessor,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/tutoriais',
    component: Tutoriais,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/:idTurma/diario/:idDiario/agenda',
    component: AgendaDiario,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/anosletivos/:idAnoLetivo/escola/:idEscola/turma/multiseriado/:idTurma/diario/agenda',
    component: AgendaDiarioMultiseriado,
    redirect: true,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
];

const routesProfile = [
  {
    path: '/dados',
    name: 'Meus Dados',
    mini: 'M',
    component: DadosUsuario,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },
  {
    path: '/mudarsenha',
    name: 'Alterar senha',
    mini: 'A',
    component: MudarSenha,
    layout: '/gestor',
    role: Papel.GESTOR.value,
  },

];

export { routesProfile };
export default routes;
