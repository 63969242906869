import React from 'react';
import { Link } from 'react-router-dom';

import {
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Button,
  Form,
  FormGroup,
  Input,
} from 'reactstrap';

import Select from 'react-select';
import Loader from 'react-loader-spinner';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import EntesService from 'services/EntesService';
import LoginService from 'services/LoginService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import TurmasService from 'services/TurmasService';
import DiariosService from 'services/DiariosService';
import RegistroAulasService from 'services/RegistroAulasService';
import ObjetivosAprendizagemCampoExperienciaService from 'services/ObjetivosAprendizagemCampoExperienciaService';
import HabilidadesCompetenciasEspecificasService from 'services/HabilidadesCompetenciasEspecificasService';

import Turno from 'assets/csv/turnos.json';
import TipoAvaliacao from 'assets/csv/tipos-avaliacao.json';
import CicloEnsino from 'assets/csv/ciclos-ensino.json';

class RegistroAula extends React.Component {
  constructor(props) {
    super(props);

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.state = {
      showAlert: false,
      showLoaderModal: false,

      objetoConhecimento: '',
      metodologia: '',

      objetivosAprendizagem: [],
      objetivosAprendizagemSelecionados: [],
      habilidades: [],
      habilidadesSelecionadas: [],

      error: null,
    };

    this.entesService = new EntesService();
    this.loginService = new LoginService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.turmasService = new TurmasService();
    this.diariosService = new DiariosService();
    this.registroAulasService = new RegistroAulasService();
    this.objetivosAprendizagemCampoExperienciaService = new ObjetivosAprendizagemCampoExperienciaService();
    this.habilidadesCompetenciasEspecificasService = new HabilidadesCompetenciasEspecificasService();
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);
    try {
      const dadosEnte = await this.entesService.carregarDadosEnte();

      const anoLetivo = await this.anosLetivosService
        .carregarAnoLetivo(this.props.match.params.idAnoLetivo);

      const escola = await this.escolasService
        .carregarEscola(this.props.match.params.idEscola);

      const turma = await this.turmasService
        .carregarTurma(this.props.match.params.idTurma);

      const diario = await this.diariosService
        .carregarDiario(this.props.match.params.idDiario);

      const registroAula = await this.registroAulasService
        .carregarRegistroAula(this.props.match.params.idRegistroAula);

      const materias = await this.registroAulasService.carregarMateriasRegistroAula(registroAula.id);
      const materiasSelecionadas = materias.map((materiaRegistroAula) => materiaRegistroAula.materia);
      materiasSelecionadas.forEach((materia) => {
        Object.assign(materia, { value: materia.id, label: materia.nome });
      });

      const habilidadesPromises = materiasSelecionadas.map((materiaSelecionada) => new Promise((resolve) => {
        const habilidades = this.habilidadesCompetenciasEspecificasService
          .carregarHabilidadesPorMateria(materiaSelecionada.id);
        resolve(habilidades);
      }));

      const habilidadesResults = await Promise.all(habilidadesPromises);
      const habilidades = [];

      habilidadesResults.forEach((habilidadesResult) => {
        habilidadesResult.forEach((habilidade) => {
          Object.assign(habilidade, { value: habilidade.id, label: habilidade.descricao });
          habilidades.push(habilidade);
        });
      });

      const objetivosAprendizagemPromises = materiasSelecionadas.map((materiaSelecionada) => new Promise((resolve) => {
        const objetivosAprendizagem = this.objetivosAprendizagemCampoExperienciaService
          .carregarObjetivosAprendizagemPorMateria(materiaSelecionada.id);
        resolve(objetivosAprendizagem);
      }));

      const objetivosAprendizagemResults = await Promise.all(objetivosAprendizagemPromises);
      const objetivosAprendizagem = [];

      objetivosAprendizagemResults.forEach((objetivosAprendizagemResult) => {
        objetivosAprendizagemResult.forEach((objetivoAprendizagem) => {
          Object.assign(objetivoAprendizagem, { value: objetivoAprendizagem.id, label: objetivoAprendizagem.descricao });
          habilidades.push(objetivoAprendizagem);
        });
      });

      const raoas = await this.registroAulasService
        .carregarObjetivosAprendizagem(this.props.match.params.idRegistroAula);

      const rahs = await this.registroAulasService
        .carregarHabilidades(this.props.match.params.idRegistroAula);

      const materia = turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
        ? 'Campo de experiências' : 'Componente curricular';

      const objetivo = turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value && anoLetivo.ente.nome !== 'Patos'
        ? 'Objetivos de aprendizagem e desenvolvimento' : 'Objetos de conhecimento';

      this.setState({
        dadosEnte,
        anoLetivo,
        escola,
        turma,
        diario,
        registroAula,
        materiasSelecionadas,
        objetivosAprendizagem,
        objetivosAprendizagemSelecionados: raoas.map((raoa) => ({ value: raoa.objetivoAprendizagem.id, label: raoa.objetivoAprendizagem.descricao })),
        habilidades,
        habilidadesSelecionadas: rahs.map((rah) => ({ value: rah.habilidade.id, label: rah.habilidade.descricao })),
        materia,
        objetivo
      });

    } catch (e) {
      this.setState({ erro: true });
    }
  }

  voltar() {
    if (this.state.turma.tipoAvaliacao === TipoAvaliacao.NOTA.value) {
      return `${this.props.layout}`
        + `/anosletivos/${this.props.match.params.idAnoLetivo}`
        + `/escola/${this.state.escola.id}`
        + `/turma/${this.state.turma.id}`
        + `/diario/${this.state.diario.id}`;
    }
    return `${this.props.layout}`
      + `/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.state.escola.id}`
      + `/turma/${this.state.turma.id}`
      + `/diario/${this.state.diario.id}/alfabetizacao`;
  }

  conteudoPagina() {
    return this.state.registroAula ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            {
              !this.state.turma.multiSeriado
                ? <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
                : <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turma.turmaMultiseriado.id}`}>{this.state.turma.turmaMultiseriado.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
            }
            {
              !this.state.turma.multiSeriado
                ? <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}/diario/${this.state.diario.id}`}>{this.state.diario.nome}</Link></BreadcrumbItem>
                : <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turma.turmaMultiseriado.id}/diario`}>Diário único</Link></BreadcrumbItem>
            }
            {
              !this.state.turma.multiSeriado
                ? <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}/diario/${this.state.diario.id}/registroaulas`}>Registro de Aulas</Link></BreadcrumbItem>
                : <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turma.turmaMultiseriado.id}/diario/registroaulas`}>Registro de Aulas</Link></BreadcrumbItem>
            }
            <BreadcrumbItem active>Registro de aula</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Form onSubmit={this.onSubmit}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Registro de aula
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="5">
                    <FormGroup className={'has-label'}>
                      <label>Autor</label>
                      <Input
                        disabled
                        value={this.state.registroAula.vinculo.nomeUsuario}
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="2">
                    <FormGroup className={'has-label'}>
                      <label>Data {this.state.dadosEnte.registroAulasPeriodo ? 'início' : ''}</label>
                      <Input
                        disabled
                        value={this.state.registroAula.data}
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                  {
                    this.state.dadosEnte.registroAulasPeriodo && this.state.turma.curso.cicloEnsino !== CicloEnsino.ENSINO_FUNDAMENTAL_2.value && <Col md="2">
                      <FormGroup className={'has-label'}>
                        <label>Data fim</label>
                        <Input
                          disabled
                          value={this.state.registroAula.dataFim}
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  }
                  <Col md="8">
                    <FormGroup className={'has-label has-danger'}>
                      <label>{this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
                        ? 'Campo de experiências' : 'Componente curricular'} *</label>
                      <Select
                        isDisabled
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={'react-select primary'}
                        classNamePrefix="react-select"
                        isMulti
                        value={this.state.materiasSelecionadas}
                        options={this.state.selectMaterias}
                        placeholder={this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
                          ? 'Campo de experiências' : 'Componente curricular'}
                      />
                    </FormGroup>
                  </Col>
                  {
                    !(this.state.dadosEnte.registroAulasPeriodo && this.state.turma.curso.cicloEnsino !== CicloEnsino.ENSINO_FUNDAMENTAL_2.value) && <Col md="2">
                      <FormGroup className={`has-label ${this.state.nomeState}`}>
                        <label>Nº de aulas</label>
                        <Input
                          disabled
                          value={this.state.registroAula.numeroAulas}
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  }
                </Row>
                {
                  this.state.dadosEnte?.habilidadesRegistroAula && <Row>
                    <Col>
                      {
                        (this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value && !this.state.dadosEnte.bnccNacional) ? <FormGroup className={'has-label'}>
                          <label>Objetivos de aprendizagem e desenvolvimento</label>
                          <Select
                            isDisabled
                            noOptionsMessage={() => 'Nenhuma entrada'}
                            className={'react-select primary'}
                            classNamePrefix="react-select"
                            placeholder="Objetivos de aprendizagem..."
                            closeMenuOnSelect={false}
                            isMulti
                            value={this.state.objetivosAprendizagemSelecionados}
                            onChange={(event) => {
                              this.setState({
                                objetivosAprendizagemSelecionados: event || [],
                              });
                            }}
                            options={this.state.objetivosAprendizagem}
                          />
                        </FormGroup> : <FormGroup className={'has-label'}>
                          <label>{this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value ? 'Objetivos de aprendizagem e desenvolvimento' : 'Habilidades'}</label>
                          <Select
                            isDisabled
                            noOptionsMessage={() => 'Nenhuma entrada'}
                            className={'react-select primary'}
                            classNamePrefix="react-select"
                            placeholder={this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value ? 'Objetivos de aprendizagem e desenvolvimento...' : 'Habilidades...'}
                            closeMenuOnSelect={false}
                            isMulti
                            value={this.state.habilidadesSelecionadas}
                            onChange={(event) => {
                              this.setState({
                                habilidadesSelecionadas: event || [],
                              });
                            }}
                            options={this.state.habilidades}
                          />
                        </FormGroup>
                      }
                    </Col>
                  </Row>
                }
                {
                  ((!this.state.dadosEnte?.registroAulasPeriodo && this.state.dadosEnte?.habilidadesRegistroAula && !(this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value)) || !this.state.dadosEnte?.habilidadesRegistroAula) && <Row>
                    <Col>
                      <FormGroup className={'has-label'}>
                        <label>{this.state.objetivo}</label>
                        <Input
                          disabled
                          value={this.state.registroAula.objetoConhecimento}
                          maxLength="500"
                          type="textarea"
                          style={{ height: '300px' }} />
                      </FormGroup>
                    </Col>
                  </Row>
                }
                <Row>
                  <Col>
                    <FormGroup className={'has-label'}>
                      <label>Metodologia</label>
                      <Input
                        disabled
                        value={this.state.registroAula.metodologia}
                        type="textarea"
                        style={{ height: '300px' }} />
                    </FormGroup>
                  </Col>
                </Row>
                {
                  this.state.dadosEnte.registroAulasPeriodo && <Row>
                    <Col>
                      <FormGroup className={'has-label'}>
                        <label>Observações</label>
                        <Input
                          disabled
                          value={this.state.registroAula.observacoes}
                          type="textarea"
                          style={{ height: '300px' }} />
                      </FormGroup>
                    </Col>
                  </Row>
                }
                {
                  !this.state.dadosEnte.habilidadesRegistroAula && this.state.dadosEnte?.habilidadesRegistroAula && this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value && <Row>
                    <Col>
                      <FormGroup className={'has-label'}>
                        <label>{this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
                          ? 'Direitos de aprendizagem' : 'Competências específicas'}</label>
                        <Input
                          disabled
                          value={this.state.registroAula.direitosAprendizagem}
                          type="textarea"
                          style={{ height: '300px' }} />
                      </FormGroup>
                    </Col>
                  </Row>
                }
              </CardBody>
              <CardFooter>
                <Row>
                  <Col className="text-left">
                    <Button
                      color="primary"
                      onClick={() => this.props.history.goBack()} >
                      Voltar
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Form>
        </Col>
      </Row>
    </> : <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#053d7c"
          height="50"
          width="50" />
      </div>
    </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text={this.state.loaderModalText} />
      </div>
    );
  }
}

export default RegistroAula;
