/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all
* copies or substantial portions of the Software.

*/
import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Table,
  UncontrolledTooltip,
  Breadcrumb,
  BreadcrumbItem,
  Label,
} from 'reactstrap';

import { connect } from 'react-redux';

import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Loader from 'react-loader-spinner';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import validarCpf from 'validar-cpf';
import isDateFormat from 'is-date-format';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';
import ConfirmModal from 'views/components/ConfirmModal';
import ImageCroppModal from 'views/components/ImageCroppModal';
import ImageSourceModal from 'views/components/ImageSourceModal';
import WebCamModal from 'views/components/WebCamModal';

import UsuarioService from 'services/UsuarioService';
import VinculoService from 'services/VinculoService';
import LoginService from 'services/LoginService';
import EntesService from 'services/EntesService';

import Escolaridade from 'assets/csv/escolaridades.json';
import Sexo from 'assets/csv/sexos.json';
import Etnia from 'assets/csv/etnias.json';
import Papel from 'assets/csv/papeis.json';
import Situacao from 'assets/csv/situacoes-vinculo.json';

import { editFotoUsuario } from 'redux/actions/DadosUsuarioActions';

class UserProfile extends React.Component {
  constructor(props) {
    super(props);

    this.escolaridades = [
      Escolaridade.ENSINO_MEDIO_COMPLETO,
      Escolaridade.ENSINO_SUPERIOR_INCOMPLETO,
      Escolaridade.ENSINO_SUPERIOR_COMPLETO,
    ];

    this.sexos = [
      Sexo.MASCULINO,
      Sexo.FEMININO,
    ];

    this.etnias = [
      Etnia.BRANCA,
      Etnia.PRETA,
      Etnia.PARDA,
      Etnia.AMARELA,
      Etnia.NAO_DECLARADA,
      Etnia.INDIGENA,
    ];

    this.papeis = [
      Papel.GESTOR,
      Papel.DIRETOR,
      Papel.PROFESSOR,
      Papel.ADMINISTRADOR,
      Papel.ALUNO,
      Papel.PAIS,
      Papel.COORDENADOR_PEDAGOGICO,
      Papel.TREINAMENTO,
      Papel.SECRETARIO,
    ];

    this.situacoes = [
      Situacao.ATIVO,
      Situacao.INATIVO,
    ];

    this.fileInputRef = React.createRef();

    this.usuarioService = new UsuarioService();
    this.vinculoService = new VinculoService();
    this.loginService = new LoginService();
    this.entesService = new EntesService();

    this.usuario = null;

    this.state = {
      showAlert: false,
      showImageCropperModal: false,
      showImageSourceModal: false,
      showWebCamModal: false,
      showLoaderModal: false,
      showVerificarTelefoneModal: false,

      buttonDisabled: true,

      login: '',
      email: '',
      matricula: '',
      telefone: '',
      nome: '',
      cpf: '',
      nomeMae: '',
      nomePai: '',
      endereco: '',
      detalhesEscolaridade: '',
      dataNascimento: '',
      nis: '',
      receberNotificacoesNoticias: true,
      receberNotificacoesTurmas: true,

      nomeState: '',
      cpfState: '',
      matriculaState: '',
      telefoneState: '',
      dataNascimentoState: '',
      nisState: '',

      selectAlunos: [],

      filhos: [],

      escolaridadeSelecionada: null,
      sexoSelecionado: null,
      etniaSelecionada: null,

      vinculos: [],
    };

    this.carregarUsuario = this.carregarUsuario.bind(this);
    this.imageChange = this.imageChange.bind(this);
    this.atualizarVinculo = this.atualizarVinculo.bind(this);
    this.atualizarMatricula = this.atualizarMatricula.bind(this);
  }

  componentWillUnmount() {
    this.usuarioService.abortPedingRequests();
    this.vinculoService.abortPedingRequests();
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);

    const dadosEnte = await this.entesService.carregarDadosEnte();

    this.debouncedFunction = _.debounce((text) => {
      if (text === '') {
        this.setState({ carregandoAlunos: false, selectAlunos: [] });
      } else {
        this.carregarAlunos(text);
      }
    }, 1500);

    const { idUsuario } = this.props.match.params;
    if (idUsuario !== undefined) {
      this.disableFields = true;

      const path = this.props.location.pathname.match(/\/\w+?\/(\w+?)\/.*/);

      switch (path[1]) {
        case 'gestores':
          this.path = '/admin/gestores';
          this.tipo = 'Gestor';
          this.anterior = 'Gestores';
          this.tipoVinculo = Papel.GESTOR.value;
          this.vinculo = Papel.GESTOR.label;
          break;
        case 'servidores': {
          const path2 = this.props.location.pathname.match(/\/\w+?\/\w+?\/(\w+?)\/.*/);
          this.anterior = 'Servidores';
          this.path = '/gestor/servidores';
          switch (path2[1]) {
            case 'diretores':
              this.tipo = 'Diretores';
              this.tipoVinculo = Papel.DIRETOR.value;
              this.vinculo = Papel.DIRETOR.label;
              break;
            case 'secretarios':
              this.tipo = 'Secretários';
              this.tipoVinculo = Papel.SECRETARIO.value;
              this.vinculo = Papel.SECRETARIO.label;
              break;
            case 'coordenadores':
              this.tipo = 'Coordenadores';
              this.tipoVinculo = Papel.COORDENADOR_PEDAGOGICO.value;
              this.vinculo = Papel.COORDENADOR_PEDAGOGICO.label;
              break;
            case 'professores':
              this.tipo = 'Professores';
              this.tipoVinculo = Papel.PROFESSOR.value;
              this.vinculo = Papel.PROFESSOR.label;
              break;
            default:
              break;
          }
        }
        break;
        case 'pais': {
          this.path = '/gestor/pais';
          this.tipo = 'Pais';
          this.anterior = 'Pais';
          this.tipoVinculo = Papel.PAIS.value;
          this.vinculo = Papel.PAIS.label;
          this.carregarFilhosPais();
          break;
        }
        default:
          break;
      }
    } else {
      this.vinculo = this.props.dadosUsuario.papel;
      this.disableFields = this.props.role === Papel.ALUNO.value;
      this.tipoVinculo = this.papeis[this.props.role].value;
      this.vinculo = this.papeis[this.props.role].label;
    }
    await this.carregarUsuario(idUsuario);

    this.setState({ dadosEnte })
  }

  async carregarUsuario(idUsuario) {
    this.setState({ showAlert: false });

    try {
      const usuario = await this.usuarioService.carregarUsuario(idUsuario);

      const vinculos = this.props.role === Papel.GESTOR.value || this.props.role === Papel.ADMINISTRADOR.value ? 
        await this.usuarioService.carregarVinculosUsuario(usuario.id) : 
        await this.usuarioService.carregarVinculosUsuarioAtivo();

      Object.assign( usuario, { vinculos });

      if (idUsuario) {
        const foto = await this.usuarioService
          .carregarFoto(idUsuario);

        Object.assign(usuario, { foto });
      } else {
        Object.assign(usuario, { foto: this.props.dadosUsuario.foto });
      }

      this.usuario = usuario;

      let matricula = '';
      if (this.props.match.params.idUsuario) {
        const vinculo = usuario.vinculos.filter((vinculo) => vinculo.idUsuario === idUsuario);
        matricula = vinculo[0]?.matricula || '';
      } else {
        const vinculo = usuario.vinculos.filter((vinculo) => vinculo.id === this.props.dadosUsuario.idVinculo);
        matricula = vinculo[0]?.matricula || '';
      }

      this.setState({
        vinculos: this.usuario.vinculos,
        matricula,
      });

      this.contentLoaded();
    } catch (e) {
      this.setState({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar dados do usuário',
      });
    }
  }

  contentLoaded() {
    this.setState({
      login: this.usuario.credenciais.login || '',
      email: this.usuario.credenciais.email || '',
      telefone: this.usuario.credenciais.telefone || '',
      nome: this.usuario.nome || '',
      cpf: this.usuario.cpf || '',
      nomeMae: this.usuario.nomeMae || '',
      nomePai: this.usuario.nomePai || '',
      endereco: this.usuario.endereco || '',
      detalhesEscolaridade: this.usuario.detalhesEscolaridade || '',
      dataNascimento: this.usuario.dataNascimento || '',
      nis: this.usuario.nis || '',
      buttonDisabled: false,
      escolaridadeSelecionada: this.usuario.escolaridade !== undefined
        ? this.escolaridades[this.usuario.escolaridade] : null,
      sexoSelecionado: this.usuario.sexo !== undefined
        ? this.sexos[this.usuario.sexo] : null,
      etniaSelecionada: this.usuario.etnia !== undefined
        ? this.etnias[this.usuario.etnia] : null,
      receberNotificacoesNoticias: this.usuario.receberNotificacoesNoticias,
      receberNotificacoesTurmas: this.usuario.receberNotificacoesTurmas,
    });
  }

  async carregarAlunos(like) {
    try {
      let data;
      if (this.state.dadosEnte.alunosPorEscola && (this.props.role === Papel.DIRETOR.value
        || this.props.role === Papel.SECRETARIO.value)) {
        data = await this.usuarioService
          .carregarUsuariosVinculadosDiretor(Papel.ALUNO.value, 1, 9999999, like);
      } else {
        data = await this.usuarioService
          .carregarUsuarios(Papel.ALUNO.value, 1, 9999999, like);
      }

      const selectAlunos = [];
      data.usuarios.forEach((aluno) => {
        selectAlunos.push({ value: aluno.id, label: `${aluno.nome} (Mãe: ${aluno.nomeMae || 'Não informado'})` });
      });

      this.setState({
        carregandoAlunos: false,
        selectAlunos,
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        carregandoAlunos: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar alunos cadastrados',
      });
    }
  }

  async carregarFilhosPais() {
    this.setState({ showAlert: false });

    try {
      const filhos = await this.usuarioService
        .carregarFilhosPais(this.props.match.params.idUsuario);

      this.setState({ filhos });
    } catch (e) {
      this.setState({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar filhos.',
      });
    }
  }

  imageChange(e) {
    this.setState({ showAlert: false });
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file) {
      reader.onloadend = () => {
        if (file.size > 1000000) {
          this.setState({
            showAlert: true,
            alertColor: 'danger',
            alertMsg: 'Erro: Imagem muito grande. O tamanho máximo para imagem é 1MB',
          });
        } else {
          const novaFoto = reader.result.substring(reader.result.indexOf(',') + 1, reader.result.length);
          this.setState({ novaFoto, showImageCropperModal: true });
        }
        this.fileInputRef.current.value = '';
      };
      reader.readAsDataURL(file);
    }
  }

  validarCampos() {
    const telefone = this.state.telefone.replace(/[()-]/g, '');

    let ret = true;
    if (this.state.nome === '') {
      this.setState({ nomeState: 'has-danger' });
      ret = false;
    }
    if (this.state.cpf !== '' && !validarCpf(this.state.cpf)) {
      this.setState({ cpfState: 'has-danger' });
      ret = false;
    }
    if (telefone.replace(/[()-]/g, '') !== ''
      && telefone.length < 11) {
      this.setState({ telefoneState: 'has-danger' });
      ret = false;
    }
    if (this.state.dataNascimento !== ''
      && !isDateFormat(this.state.dataNascimento, 'dd/mm/yyyy')) {
      this.setState({ dataNascimentoState: 'has-danger' });
      ret = false;
    }
    if (this.state.nis !== ''
      && this.state.nis.length < 11) {
      this.setState({ nisState: 'has-danger' });
      ret = false;
    }
    return ret;
  }

  async atualizarUsuario(e) {
    e.preventDefault();

    if (this.state.buttonDisabled) return;
    if (!this.validarCampos()) return;

    this.setState({ buttonDisabled: true, showLoaderModal: true, showAlert: false });

    this.usuario.credenciais.telefone = this.state.telefone
      ? this.state.telefone.replace(/[()-]/g, '') : null;
    this.usuario.matricula = this.state.matricula ? this.state.matricula : null;
    this.usuario.nome = this.state.nome ? this.state.nome : null;
    this.usuario.nomeMae = this.state.nomeMae ? this.state.nomeMae : null;
    this.usuario.nomePai = this.state.nomePai ? this.state.nomePai : null;
    this.usuario.cpf = this.state.cpf ? this.state.cpf : null;
    this.usuario.dataNascimento = this.state.dataNascimento
      ? this.state.dataNascimento : null;
    this.usuario.endereco = this.state.endereco ? this.state.endereco : null;
    this.usuario.detalhesEscolaridade = this.state.detalhesEscolaridade ? this.state.detalhesEscolaridade : null;
    this.usuario.escolaridade = this.state.escolaridadeSelecionada
      ? this.state.escolaridadeSelecionada.value : null;
    this.usuario.sexo = this.state.sexoSelecionado ? this.state.sexoSelecionado.value : null;
    this.usuario.etnia = this.state.etniaSelecionada ? this.state.etniaSelecionada.value : null;
    this.usuario.nis = this.state.nis ? this.state.nis : null;
    this.usuario.foto = this.props.dadosUsuario.foto;
    this.usuario.receberNotificacoesNoticias = this.state.receberNotificacoesNoticias;
    this.usuario.receberNotificacoesTurmas = this.state.receberNotificacoesTurmas;

    try {
      await this.usuarioService.atualizarUsuario(this.usuario);

      this.setState({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Dados atualizados com sucesso',
      });

      this.resetarPagina();
    } catch (msg) {
      this.setState({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao atualizar dados',
      });

      this.resetarPagina();
    }
  }

  async atualizarVinculo(vinculo) {
    this.setState({ showAlert: false });
    try {
      await this.vinculoService.atualizarVinculo(vinculo);

      this.props.history.replace(this.props.location.pathname, this.props.location.state);

      this.setState({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Vínculo salvo com sucesso',
      });
    } catch (e) {
      this.setState({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao salvar vínculo',
      });
    }
  }

  async atualizarMatricula() {
    this.setState({ showAlert: false, showLoaderModal: true });
    try {
      await this.vinculoService.atualizarMatricula(this.usuario.id, this.tipoVinculo, this.state.matricula);

      this.props.history.replace(this.props.location.pathname, this.props.location.state);

      this.setState({
        showAlert: true,
        showLoaderModal: false,
        alertColor: 'success',
        alertMsg: 'Matrícula atualizada com sucesso',
      });
    } catch (e) {
      this.setState({
        showAlert: true,
        showLoaderModal: false,
        alertColor: 'danger',
        alertMsg: 'Erro ao atualizar matrícula',
      });
    }
  }

  resetarPagina() {
    this.setState({
      buttonDisabled: false,
      showLoaderModal: false,
      nomeState: '',
      cpfState: '',
      telefoneState: '',
      matriculaState: '',
      dataNascimentoState: '',
      nisState: '',
    });
  }

  confirmarRemocaoFilho(idFilho) {
    this.setState({
      idFilhoRemocao: idFilho,
      showConfirmModal: true,
      textoRemocao: 'Confirme a exclusão do filho',
    });
  }

  async removerFilho() {
    try {
      this.setState({
        showLoaderModal: true,
        showAlert: false,
      });

      await this.usuarioService
        .removerPaisAluno(this.state.idFilhoRemocao,
          this.props.match.params.idUsuario);

      this.carregarFilhosPais();

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Filho removido com sucesso',
      });
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao remover filho',
      });
    }
  }

  validarCamposVinculo() {
    let ret = true;

    if (!this.state.alunoSelecionado) {
      this.setState({ alunoState: 'danger' });
      ret = false;
    }

    return ret;
  }

  async vincularPaisAluno() {
    if (this.state.showLoaderModal) return;
    if (!this.validarCamposVinculo()) return;

    try {
      this.setState({
        showLoaderModal: true,
        loaderModalText: 'Vinculando pais a aluno...',
        showAlert: false,
      });

      await this.usuarioService
        .vincularPaisAluno(this.state.alunoSelecionado.value,
          this.props.match.params.idUsuario);

      this.carregarFilhosPais();

      this.setState({
        alunoSelecionado: '',
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Vínculo adicionado com sucesso.',
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        showAlert: true,
        showLoaderModal: false,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao vincular pais ao aluno',
      });
    }
  }

  render() {
    return (
      <div className="content">
        {this.usuario && (this.props.dadosUsuario.foto || this.usuario.foto)
          ? <>
            <Row>
              <Col md="12">
                <Alert
                  color={this.state.alertColor}
                  isOpen={this.state.showAlert}
                  toggle={() => { this.setState({ showAlert: false }); }}>
                  {this.state.alertMsg}
                </Alert>
                {
                  this.disableFields && this.props.role !== Papel.ALUNO.value
                    ? <Breadcrumb>
                      <BreadcrumbItem><Link to={this.path}>{this.anterior}</Link></BreadcrumbItem>
                      <BreadcrumbItem active>{this.tipo}</BreadcrumbItem>
                    </Breadcrumb>
                    : <Breadcrumb>
                      <BreadcrumbItem active>Meus Dados</BreadcrumbItem>
                    </Breadcrumb>
                }
                <Form onSubmit={this.atualizarUsuario.bind(this)}>
                  <Card className="card-user">
                    <div className="image" >
                      <img
                        alt="..."
                        src="https://sisaeduc.s3.sa-east-1.amazonaws.com/public/bg/book-bg.jpg"
                      />
                    </div>
                    <Row>
                      <Col md="12">
                        <div className="author">
                          <div className="fileinput text-center">
                            <input
                              type="file"
                              accept="image/jpeg, image/png"
                              onChange={this.imageChange}
                              ref={this.fileInputRef} />
                          </div>
                          <img
                            alt="..."
                            className={`avatar ${!this.disableFields ? 'avatar-clickable' : ''} border-gray`}
                            src={!this.disableFields ? `data:image/jpeg;base64,${this.props.dadosUsuario.foto}` : `data:image/jpeg;base64,${this.usuario.foto}`}
                            onClick={() => {
                              if (this.disableFields) return;
                              this.setState({ showImageSourceModal: true });
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <CardBody>
                      <Row>
                        <Col md="2">
                          <FormGroup className={'has-label'}>
                            <label>Vínculo</label>
                            <Input
                              disabled
                              value={this.vinculo}
                            />
                          </FormGroup>
                        </Col>
                        {
                          this.tipoVinculo !== Papel.PAIS.value && this.tipoVinculo !== Papel.ALUNO.value && <Col md="2">
                            <FormGroup className={`has-label ${this.state.matriculaState}`}>
                              <label>Matrícula</label>
                              <Input
                                value={this.state.matricula}
                                onChange={(evt) => this.setState({
                                  matricula: evt.target.value,
                                  matriculaState: '',
                                })}
                                type="text"
                                maxLength="30"
                              />
                              {this.state.matriculaState === 'has-danger' && (
                                <label className="error">
                                  Informe a matrícula.
                                </label>
                              )}
                            </FormGroup>
                          </Col>
                        }
                      </Row>
                      <Row>
                        <Col md="3">
                          <FormGroup className={'has-label'}>
                            <label>Login *</label>
                            <Input
                              value={this.state.login}
                              onChange={(evt) => this.setState({ login: evt.target.value })}
                              type="text"
                              maxLength='50'
                              disabled
                            />
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup>
                            <label>Email *</label>
                            <Input
                              value={this.state.email}
                              onChange={(evt) => this.setState({ email: evt.target.value })}
                              type="text"
                              maxLength='100'
                              disabled
                            />
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <FormGroup className={`has-label ${this.state.telefoneState}`}>
                            <label>Telefone</label>
                            <Input
                              disabled={this.disableFields}
                              value={this.state.telefone}
                              onChange={(evt) => this.setState({ telefone: evt.target.value })}
                              type="text"
                              mask="(99)99999-9999"
                              maskChar=""
                              tag={InputMask}
                            />
                            {this.state.telefoneState === 'has-danger' && (
                              <label className="error">
                                Informe um telefone válido.
                              </label>
                            )}
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup className={`has-label ${this.state.nomeState}`}>
                            <label>Nome *</label>
                            <Input
                              value={this.state.nome}
                              onChange={(evt) => this.setState({
                                nome: evt.target.value,
                                nomeState: '',
                              })}
                              type="text"
                              maxLength="200"
                              disabled={this.disableFields}
                            />
                            {this.state.nomeState === 'has-danger' && (
                              <label className="error">
                                Informe seu nome completo.
                              </label>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <FormGroup>
                            <label>Nome da mãe</label>
                            <Input
                              value={this.state.nomeMae}
                              onChange={(evt) => this.setState({ nomeMae: evt.target.value })}
                              type="text"
                              maxLength="200"
                              disabled={this.disableFields}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <label>Nome do pai</label>
                            <Input
                              value={this.state.nomePai}
                              onChange={(evt) => this.setState({ nomePai: evt.target.value })}
                              type="text"
                              maxLength="200"
                              disabled={this.disableFields}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <FormGroup className={`has-label ${this.state.cpfState}`}>
                            <label>CPF</label>
                            <Input
                              value={this.state.cpf}
                              onChange={(evt) => this.setState({
                                cpf: evt.target.value,
                                cpfState: '',
                              })}
                              type="text"
                              mask="999.999.999-99"
                              maskChar=""
                              tag={InputMask}
                              disabled={this.disableFields}
                            />
                            {this.state.cpfState === 'has-danger' && (
                              <label className="error">
                                Informe um cpf válido.
                              </label>
                            )}
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <FormGroup className={`has-label ${this.state.dataNascimentoState}`}>
                            <label>Data de nascimento</label>
                            <Input
                              value={this.state.dataNascimento}
                              onChange={(evt) => this.setState({
                                dataNascimento: evt.target.value,
                                dataNascimentoState: '',
                              })}
                              type="text"
                              mask="99/99/9999"
                              maskChar=""
                              tag={InputMask}
                              disabled={this.disableFields}
                            />
                            {this.state.dataNascimentoState === 'has-danger' && (
                              <label className="error">
                                Informe uma data válida.
                              </label>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <FormGroup>
                            <label>Endereço</label>
                            <Input
                              value={this.state.endereco}
                              onChange={(evt) => this.setState({ endereco: evt.target.value })}
                              type="text"
                              maxLength="200"
                              disabled={this.disableFields}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <FormGroup>
                            <label>Escolaridade</label>
                            <Select
                              noOptionsMessage={() => 'Nenhuma entrada'}
                              className={'react-select primary'}
                              classNamePrefix="react-select"
                              value={this.state.escolaridadeSelecionada}
                              onChange={(event) => {
                                this.setState({ escolaridadeSelecionada: event });
                              }}
                              options={this.escolaridades}
                              placeholder="Escolaridade..."
                              isDisabled={this.disableFields}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <FormGroup>
                            <label>Sexo</label>
                            <Select
                              noOptionsMessage={() => 'Nenhuma entrada'}
                              className={'react-select primary'}
                              classNamePrefix="react-select"
                              value={this.state.sexoSelecionado}
                              onChange={(event) => {
                                this.setState({ sexoSelecionado: event });
                              }}
                              options={this.sexos}
                              placeholder="Sexo..."
                              isDisabled={this.disableFields}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <FormGroup>
                            <label>Etnia</label>
                            <Select
                              noOptionsMessage={() => 'Nenhuma entrada'}
                              className={'react-select primary'}
                              classNamePrefix="react-select"
                              value={this.state.etniaSelecionada}
                              onChange={(event) => {
                                this.setState({ etniaSelecionada: event });
                              }}
                              options={this.etnias}
                              placeholder="Etnia..."
                              isDisabled={this.disableFields}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <FormGroup className={`has-label ${this.state.nisState}`}>
                            <label>NIS</label>
                            <Input
                              value={this.state.nis}
                              onChange={(evt) => this.setState({
                                nis: evt.target.value,
                                nisState: '',
                              })}
                              type="text"
                              mask="99999999999"
                              maskChar=""
                              tag={InputMask}
                              disabled={this.disableFields}
                            />
                            {this.state.nisState === 'has-danger' && (
                              <label className="error">
                                Informe um NIS válido.
                              </label>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <FormGroup>
                            <label>Detalhes escolaridade</label>
                            <Input
                              value={this.state.detalhesEscolaridade}
                              onChange={(evt) => this.setState({ detalhesEscolaridade: evt.target.value })}
                              type="text"
                              maxLength="200"
                              disabled={this.disableFields}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="3">
                          <FormGroup check style={{ marginTop: '20px' }}>
                            <Label className="form-check-label">
                              <Input
                                disabled={this.disableFields}
                                className="form-check-input"
                                type="checkbox"
                                checked={this.state.receberNotificacoesNoticias}
                                onChange={(e) => this.setState({
                                  receberNotificacoesNoticias: e.target.checked,
                                })} />
                              Receber notificações de novas notícias
                              <span className="form-check-sign">
                                <span className="check"></span>
                              </span>
                            </Label>
                          </FormGroup>
                          <label></label>
                        </Col>
                        <Col md="3">
                          <FormGroup check style={{ marginTop: '20px' }}>
                            <Label className="form-check-label">
                              <Input
                                disabled={this.disableFields}
                                className="form-check-input"
                                type="checkbox"
                                checked={this.state.receberNotificacoesTurmas}
                                onChange={(e) => this.setState({
                                  receberNotificacoesTurmas: e.target.checked,
                                })} />
                              Receber notificações de ações em turmas
                              <span className="form-check-sign">
                                <span className="check"></span>
                              </span>
                            </Label>
                          </FormGroup>
                          <label></label>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <div className="category form-category">
                            * Campos obrigatórios
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter className="text-right">
                      {
                        !this.disableFields ? <Button
                          type="submit"
                          color="primary"
                          disabled={this.state.buttonDisabled}>
                          Atualizar
                        </Button> : this.tipoVinculo !== Papel.PAIS.value && this.tipoVinculo !== Papel.ALUNO.value && <Button
                          type="button"
                          color="primary"
                          disabled={this.state.buttonDisabled}
                          onClick={() => this.atualizarMatricula()}>
                          Atualizar matrícula
                        </Button>
                      }
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Vínculos</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <PerfectScrollbar className="sisa-perfect-scrollbar">
                    <Table>
                      <thead className="text-primary">
                        <tr>
                          <th style={{ width: '30%' }}>Papel</th>
                          <th style={{ width: '40%' }}>Ente</th>
                          <th style={{ width: '20%' }}>Situação vínculo</th>
                          {this.disableFields && this.props.role !== Papel.ALUNO.value
                            && <>
                              <th style={{ width: '10%' }} className='text-right'>Ações</th>
                            </>}
                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.state.vinculos.filter(
                            (vinculo) => vinculo.papel === this.tipoVinculo
                              || !this.props.match.params.idUsuario || (vinculo.papel === Papel.GESTOR.value && this.props.role === Papel.ADMINISTRADOR.value),
                          ).map(
                            (vinculo, idx) => (
                              <tr key={idx}>
                                <td>{this.papeis[vinculo.papel].label}</td>
                                <td>{`${vinculo.ente.nome}/${vinculo.ente.estado.uf}`}</td>
                                <td>
                                  {this.disableFields && this.props.role !== Papel.ALUNO.value
                                    ? <Select
                                      noOptionsMessage={() => 'Nenhuma entrada'}
                                      className={'react-select primary'}
                                      classNamePrefix="react-select"
                                      value={this.situacoes[vinculo.situacao]}
                                      onChange={(event) => {
                                        Object.assign(vinculo, { situacao: event.value });
                                        this.setState({ vinculos: this.state.vinculos });
                                      }}
                                      options={this.situacoes}
                                      placeholder="Situação..."
                                    />
                                    : this.situacoes[vinculo.situacao].label
                                  }
                                </td>
                                {this.disableFields && this.props.role !== Papel.ALUNO.value
                                  && <>
                                    <td className='td-actions text-right'>
                                      <div>
                                        <Button
                                          id={`btnEditarItem${idx}`}
                                          className="btn-icon"
                                          color="success"
                                          size="sm"
                                          onClick={() => this.atualizarVinculo(vinculo)}
                                          type="button">
                                          <i className="fa fa-save" />
                                        </Button>
                                        <UncontrolledTooltip placement="bottom" target={`btnEditarItem${idx}`} delay={0}>
                                          Salvar
                                        </UncontrolledTooltip>
                                      </div>
                                    </td>
                                  </>}
                              </tr>
                            ),
                          )
                        }
                      </tbody>
                    </Table>
                    </PerfectScrollbar>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {
              this.tipoVinculo === Papel.PAIS.value && this.props.role !== Papel.PAIS.value && <Row>
                <Col md="12">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">Filhos</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md="4">
                          <FormGroup className={'has-label has-danger'}>
                            <label>Aluno *</label>
                            <Select
                              isLoading={this.state.carregandoAlunos}
                              loadingMessage={() => 'Carregando...'}
                              noOptionsMessage={() => 'Nenhuma entrada'}
                              className={`react-select primary ${this.state.alunoState}`}
                              classNamePrefix="react-select"
                              value={this.state.alunoSelecionado}
                              onInputChange={(text) => {
                                this.setState({ carregandoAlunos: text !== '', alunoState: 'primary' });
                                this.debouncedFunction(text);
                              }}
                              onChange={(event) => {
                                this.setState({ alunoState: 'primary', alunoSelecionado: event });
                              }}
                              options={this.state.selectAlunos}
                              placeholder="Digite o nome do aluno..."
                            />
                            {this.state.alunoState === 'danger' ? (
                              <label className="error">
                                Informe o nome do aluno.
                              </label>
                            ) : <label></label>}
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <Button
                            style={{ marginTop: '25px' }}
                            color="primary"
                            onClick={() => this.vincularPaisAluno()}>
                            Vincular
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          {
                            this.state.filhos.length ? <PerfectScrollbar className="sisa-perfect-scrollbar">
                              <Table hover>
                              <thead className="text-primary">
                                <tr>
                                  <th style={{ width: '95%' }}>Nome</th>
                                  <th className="text-center">Ações</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  this.state.filhos.map((filho, idx) => <tr key={idx}>
                                    <td>{filho.nome}</td>
                                    <td className="text-center">
                                      <Button
                                        id={`btnRemover${idx}`}
                                        className="btn-icon"
                                        color="danger"
                                        size="sm"
                                        onClick={() => {
                                          this.confirmarRemocaoFilho(filho.id);
                                        }}
                                        type="button">
                                        <i className="fa fa-times" />
                                      </Button>
                                      <UncontrolledTooltip placement="bottom" target={`btnRemover${idx}`} delay={0}>
                                        Excluir
                                      </UncontrolledTooltip>
                                    </td>
                                  </tr>)
                                }
                              </tbody>
                            </Table> 
                            </PerfectScrollbar> : <><div className="text-center">Nenhum aluno vinculado como filho</div><br /></>
                          }
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            }
          </>
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              <Loader
                type="Oval"
                color="#053d7c"
                height="50"
                width="50" />
            </div>
          </Card>
        }
        <ImageSourceModal
          isOpen={this.state.showImageSourceModal}
          callback={(source) => {
            this.setState({ showImageSourceModal: false });
            if (source === 1) {
              this.setState({ showWebCamModal: true });
            } else {
              this.fileInputRef.current.click();
            }
          }}
        />
        <ImageCroppModal
          isOpen={this.state.showImageCropperModal}
          aspect={3 / 4}
          callback={(foto) => {
            this.setState({ showImageCropperModal: false });
            if (foto) {
              this.props.editFotoUsuario(foto);
              this.fileInputRef.current.value = '';
            } else if (foto === null) {
              this.setState({
                showAlert: true,
                alertColor: 'danger',
                alertMsg: 'Ocorreu um erro ao recortar imagem.',
              });
            } else {
              this.fileInputRef.current.value = '';
            }
          }}
          src={`data:image/jpeg;base64,${this.state.novaFoto}`} />
        <WebCamModal
          isOpen={this.state.showWebCamModal}
          callback={(foto) => {
            this.setState({ showWebCamModal: false });
            if (foto) {
              this.setState({
                showImageCropperModal: true,
                novaFoto: foto,
              });
            } else if (foto === null) {
              this.setState({
                showAlert: true,
                alertColor: 'danger',
                alertMsg: 'Não foi possível obter a imagem da webcam. Verifique se seu computador possui uma webcam ou se ela está em uso por outro programa.',
              });
            }
          }}
        />
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text='Atualizando...' />
        <ConfirmModal
          isOpen={this.state.showConfirmModal}
          callback={(confirm) => {
            this.setState({ showConfirmModal: false });
            if (confirm) {
              this.removerFilho();
            } else {
              this.setState({
                idFilhoRemocao: null,
              });
            }
          }}
          text={this.state.textoRemocao} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ dadosUsuario: state.dadosUsuario });

export default connect(mapStateToProps, { editFotoUsuario })(UserProfile);
