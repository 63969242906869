import React from 'react';
import { Link } from 'react-router-dom';

import {
  Card,
  CardBody,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Row,
  Button,
} from 'reactstrap';

import ReCAPTCHA from 'react-google-recaptcha';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import LoginService from 'services/LoginService';

import RootFooter from './Footer';

import env from 'conf/env.json';

import 'views/raiz/assets/css/style.css';

class Inicio extends React.Component {
  constructor(props) {
    super(props);

    this.loginRef = React.createRef();
    this.senhaRef = React.createRef();
    this.recaptchaRef = React.createRef();

    this.enviar = this.enviar.bind(this);
    this.enviarDados = this.enviarDados.bind(this);
    this.redoCaptcha = this.redoCaptcha.bind(this);
    this.onVerifyRecaptcha = this.onVerifyRecaptcha.bind(this);
    this.onErrorRecaptcha = this.onErrorRecaptcha.bind(this);

    this.loginService = new LoginService();

    this.state = {
      showAlert: false,

      loginState: '',
      senhaState: '',

      height: window.innerHeight,

      buttonDisabled: false,

      secure: true,
    };
  }

  componentDidMount = () => {
    const params = new URLSearchParams(this.props.location.search);

    window.addEventListener('resize', () => {
      this.setState({
        height: window.innerHeight,
      });
    });

    const cadastro = params.get('cadastro');
    const msg = params.get('msg');

    if (cadastro === 'ok') {
      this.setState({
        showAlert: true,
        alertColor: 'success',
        alertMsg: msg,
      });
    } else if (cadastro === 'fail') {
      this.setState({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg,
      });
    }
  }

  componentWillUnmount() {
    this.loginService.abortPedingRequests();
  }

  validarCampos() {
    let ret = true;
    if (this.loginRef.current.value === '') {
      this.setState({ loginState: 'has-danger' });
      ret = false;
    }
    if (this.senhaRef.current.value === '') {
      this.setState({ senhaState: 'has-danger' });
      ret = false;
    }

    return ret;
  }

  async enviar() {
    if (this.state.buttonDisabled) return;
    if (!this.validarCampos()) return;

    // this.enviarDados();
    this.recaptchaRef.current.execute();
  }

  async enviarDados(token) {
    this.setState({ buttonDisabled: true, showAlert: false });
    const login = this.loginRef.current.value;

    try {
      const usuario = await this.loginService
        .enviar(login, this.senhaRef.current.value, token);

      this.props.history.push({
        pathname: '/login/escolhervinculo',
        state: {
          login,
          senha: this.senhaRef.current.value,
          usuario,
        },
      });
    } catch (msg) {
      this.recaptchaRef.current.reset();
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Ocorreu um erro ao realizar login.',
      });
    }
  }

  redoCaptcha() {
    this.recaptchaRef.current.reset();
    this.recaptchaRef.current.execute();
  }

  onVerifyRecaptcha(token) {
    this.enviarDados(token);
  }

  onErrorRecaptcha() {
    this.setState({
      buttonDisabled: false,
      showAlert: true,
      alertColor: 'danger',
      alertMsg: 'Erro: sua interação foi identificada como um robô. Por favor, recarregue a página e tente novamente.',
    });
  }

  render() {
    const {
      loginState,
      senhaState,
    } = this.state;

    return <>
      <main id="main"
        style={{
          backgroundImage: `url("https://sisaeduc.s3.sa-east-1.amazonaws.com/public/bg/login-background.jpg")`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          width: '100vw',
          height: '100vh'
        }}>
        <div class="topnav">
		<div className="container">
          <Alert
            style={{ marginTop: '20px' }}
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
        </div>
          <div class="login-container">
            <Form id="loginForm" className="form" onSubmit={(e) => {
              e.preventDefault();
              this.enviar();
            }}>
              <div>
                <InputGroup className={`has-label ${loginState}`}>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-user" style={{ marginRight: '10px' }} />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    innerRef={this.loginRef}
                    placeholder="Login..."
                    type="text"
                    maxLength="100"
                    onChange={() => { this.setState({ loginState: '' }); }} />
                  {this.state.loginState === 'has-danger' ? (
                    <label className="error">
                      Informe o login.
                    </label>
                  ) : null}
                </InputGroup>
              </div>
              <div>
                <InputGroup className={`has-label ${senhaState}`}>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-key" style={{ marginRight: '10px' }} />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    innerRef={this.senhaRef}
                    placeholder="Senha..."
                    type={this.state.secure ? 'password' : 'text'}
                    autoComplete="off"
                    onChange={() => { this.setState({ senhaState: '' }); }} />
                  {this.state.senhaState === 'has-danger' ? (
                    <label className="error">
                      Informe a senha.
                    </label>
                  ) : null}
                </InputGroup>
                <div className="login-links">
                  <Link onClick={() => this.setState({ secure: !this.state.secure })}>
                    <i className={this.state.secure ? 'fas fa-eye' : 'fas fa-eye-slash'} style={{ marginLeft: '10px' }} /> {this.state.secure ? 'Ver' : 'Ocultar'} senha
                  </Link>
                  <Link to="/login/recuperacaosenha">
                    Recuperar senha
                  </Link>
                </div>
              </div>
              <div>
                <Button
                  disabled={this.state.buttonDisabled}
                  color="primary"
                  type="submit">
                  Entrar
                </Button>
              </div>
              <ReCAPTCHA
                ref={this.recaptchaRef}
                size="invisible"
                onExpired={this.redoCaptcha}
                onChange={this.onVerifyRecaptcha}
                onError={this.onErrorRecaptcha}
                sitekey={env.SITEKEY}
              />
            </Form>
          </div>
        </div>
        <div className="centerpage container">
          <Row>
            <Col md={{ size: 3, offset: 3 }}>
              <Card className="card-root">
                <CardBody>
                  <img alt="inova logo" src="https://sisaeduc.s3.sa-east-1.amazonaws.com/public/logo-inova.png" />
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card className="card-root">
                <CardBody>
                  <img alt="inova logo" src="https://sisaeduc.s3.sa-east-1.amazonaws.com/public/governo-patos.png" />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </main>
      <RootFooter />
      <LoaderModal
        isOpen={this.state.buttonDisabled}
        text='Realizando login...' />
    </>
  }
}

export default Inicio;
