import BaseService from './BaseService';

class RegistroDiarioAlunoService extends BaseService {

  cadastrarRegistrosDiarioAlunos(idDiario, registrosDiarioAlunos) {
    const url = `/registrodiarioaluno/diario/${idDiario}`;
    
    const requestInfo = {
      method: 'post',
      url,
      data: JSON.stringify(registrosDiarioAlunos),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
  
  atualizarRegistroDiarioAluno(idDiario, registroDiarioAluno) {
    const url = `/registrodiarioaluno/diario/${idDiario}`;

    const requestInfo = {
      method: 'put',
      url,
      data: JSON.stringify(registroDiarioAluno),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerRegistroDiarioAluno(idRegistroDiarioAluno) {
    const requestInfo = {
      method: 'delete',
      url: `/registrodiarioaluno/${idRegistroDiarioAluno}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarRegistroDiarioAluno(idRegistroDiarioAluno) {
    const requestInfo = {
      method: 'get',
      url: `/registrodiarioaluno/${idRegistroDiarioAluno}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarRegistrosDiarioAluno(idDiario, idMatricula, pagina, linhasPorPagina) {
    let url = `/registrodiarioaluno/diario/${idDiario}/matricula/${idMatricula}/page/${pagina}/pagesize/${linhasPorPagina}`;

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarRegistroDiarioAlunoFotos(idRegistroDiarioAluno) {
    let url = `/registrodiarioaluno/${idRegistroDiarioAluno}/fotos`;

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarTodosOsRegistrosDiarioAluno(idMatricula) {
    let url = `/registrodiarioaluno/matricula/${idMatricula}`;

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

}

export default RegistroDiarioAlunoService;
