import BaseService from './BaseService';

class DiariosService extends BaseService {
  carregarDiario(idDiario) {
    const requestInfo = {
      method: 'get',
      url: `/diario/${idDiario}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarDiariosDoProfessor(idTurma) {
    const requestInfo = {
      method: 'get',
      url: `/diario/turma/${idTurma}/professor`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarDiariosDaTurma(idTurma) {
    const requestInfo = {
      method: 'get',
      url: `/diario/turma/${idTurma}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarMaterias(idDiario) {
    const requestInfo = {
      method: 'get',
      url: `/diario/${idDiario}/materias`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarMateriasDoProfessor(idDiario) {
    const requestInfo = {
      method: 'get',
      url: `/diario/${idDiario}/materias/professor`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarMateriasDoProfessorNoDia(idDiario, dia) {
    const requestInfo = {
      method: 'get',
      url: `/diario/${idDiario}/materias/professor/dia/${dia}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarDiasDeAulaDoProfessor(idDiario) {
    const requestInfo = {
      method: 'get',
      url: `/diario/${idDiario}/dias/aula/professor`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarQuantidadeDeAulasDoProfessor(idDiario, dia) {
    const requestInfo = {
      method: 'get',
      url: `/diario/${idDiario}/dia/${dia}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarQuantidadeDeAulasDoProfessorMateria(idDiario, idMateria, dia) {
    const requestInfo = {
      method: 'get',
      url: `/diario/${idDiario}/materia/${idMateria}/dia/${dia}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  consolidarDiario(idDiario) {
    const requestInfo = {
      method: 'get',
      url: `/diario/consolidar/${idDiario}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  reabrirDiario(idDiario) {
    const requestInfo = {
      method: 'get',
      url: `/diario/reabrir/${idDiario}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarMatriculas(idDiario) {
    const requestInfo = {
      method: 'get',
      url: `/diario/${idDiario}/matriculas`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarMatriculasNaMateria(idDiario, idMateria) {
    const requestInfo = {
      method: 'get',
      url: `/diario/${idDiario}/matriculas/materia/${idMateria}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirDiario(idDiario) {
    const requestInfo = {
      method: 'get',
      url: `/diario/${idDiario}/imprimir`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirDiarioMultiseriado(idTurma) {
    const requestInfo = {
      method: 'get',
      url: `/diario/turma/multiseriado/${idTurma}/imprimir`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirAtaFinal(idDiario) {
    const requestInfo = {
      method: 'get',
      url: `/diario/${idDiario}/imprimir/atafinal`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirAtaFinalMultiseriado(idTurma) {
    const requestInfo = {
      method: 'get',
      url: `/diario/turma/multiseriado/${idTurma}/imprimir/atafinal`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirQuadroFrequencia(idDiario) {
    const requestInfo = {
      method: 'get',
      url: `/diario/${idDiario}/imprimir/quadro/frequencia`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirQuadroFrequenciaMultiseriado(idTurma) {
    const requestInfo = {
      method: 'get',
      url: `/diario/turma/multiseriado/${idTurma}/imprimir/quadro/frequencia`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarAulasLancadas(idDiario) {
    const requestInfo = {
      method: 'get',
      url: `/diario/${idDiario}/aulaslancadas`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirListaAssinaturaAlunos(idDiario) {
    const requestInfo = {
      method: 'get',
      url: `/diario/${idDiario}/imprimir/lista/assinatura/alunos`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirListaAssinaturaAlunosMultiseriado(idTurma) {
    const requestInfo = {
      method: 'get',
      url: `/diario/turma/multiseriado/${idTurma}/imprimir/lista/assinatura/alunos`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirBoletinsAlunos(idDiario) {
    const requestInfo = {
      method: 'get',
      url: `/diario/${idDiario}/imprimir/boletins/alunos`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirBoletinsAlunosMultiseriado(idTurma) {
    const requestInfo = {
      method: 'get',
      url: `/diario/turma/multiseriado/${idTurma}/imprimir/boletins/alunos`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRegistrosAula(idDiario) {
    const requestInfo = {
      method: 'get',
      url: `/diario/${idDiario}/imprimir/registros/aula`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRegistrosAulaMultiseriado(idTurma) {
    const requestInfo = {
      method: 'get',
      url: `/diario/turma/multiseriado/${idTurma}/imprimir/registros/aula`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarDiariosPorAnoLetivoEEscola(idAnoLetivo, idEscola, situacao,
    pagina, linhasPorPagina, like) {
    let url = `/diario/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }
    if (situacao === 0 || situacao) {
      url += `/situacao/${situacao}`;
    }
    url += `/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarDiariosDoProfessorPorAnoLetivo(idAnoLetivo, pagina, linhasPorPagina, like) {
    let url = `/diario/anoletivo/${idAnoLetivo}/professor/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarDiariosDoAlunoNaTurma(idMatricula, idTurma) {
    const requestInfo = {
      method: 'get',
      url: `/diario/matricula/${idMatricula}/turma/${idTurma}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  salvarArquivoDiarioMateria(idDiario, idMateria, arquivo) {
    let url = `/diario/${idDiario}/upload/arquivo`;
    if (idMateria) {
      url += `/materia/${idMateria}`;
    }

    const requestInfo = {
      method: 'post',
      url,
      data: JSON.stringify(arquivo),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarArquivosDiarioMateria(idDiario, idMateria, pagina, linhasPorPagina, like) {
    let url = `/diario/${idDiario}/arquivos`;
    if (idMateria) {
      url += `/materia/${idMateria}`;
    }

    url += `/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }
    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  salvarArquivoRelatorioDiario(idDiario, arquivo) {
    const url = `/diario/${idDiario}/upload/arquivorelatorio`;

    const requestInfo = {
      method: 'post',
      url,
      data: JSON.stringify(arquivo),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarArquivosRelatorioDiario(idDiario, pagina, linhasPorPagina, like) {
    let url = `/diario/${idDiario}/arquivosrelatorio/page/${pagina}/pagesize/${linhasPorPagina}`;

    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerArquivoDiarioMateria(idArquivo) {
    const requestInfo = {
      method: 'delete',
      url: `/diario/arquivo/${idArquivo}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerArquivoRelatorioDiario(idArquivo) {
    const requestInfo = {
      method: 'delete',
      url: `/diario/arquivorelatorio/${idArquivo}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarDiariosDaTurmaMultiseriado(idTurma) {
    const requestInfo = {
      method: 'get',
      url: `/diario/turma/multiseriado/${idTurma}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  consolidarDiarioTurmaMultiseriado(idTurma) {
    const requestInfo = {
      method: 'get',
      url: `/diario/consolidar/turma/multiseriado/${idTurma}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  reabrirDiarioTurmaMultiseriado(idTurma) {
    const requestInfo = {
      method: 'get',
      url: `/diario/reabrir/turma/multiseriado/${idTurma}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarDiariosDoProfessorMultiseriadoPorAnoLetivo(idAnoLetivo, pagina, linhasPorPagina, like) {
    let url = `/diario/anoletivo/${idAnoLetivo}/professor/multiseriado/page/${pagina}/pagesize/${linhasPorPagina}`;
    if (like.length > 0) {
      url += `/like/${like}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarHorariosAulasPorDia(idDiario, dia) {
    const requestInfo = {
      method: 'get',
      url: `/diario/${idDiario}/horarios/aulas/dia/${dia}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerDiario(idDiario, senha) {
    const requestInfo = {
      method: 'delete',
      url: `/diario/${idDiario}`,
      data: senha,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

}

export default DiariosService;
