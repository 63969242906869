import React from 'react';

import { connect } from 'react-redux';

import { NavLink } from 'react-router-dom';
import { Nav, Collapse } from 'reactstrap';

import EntesService from 'services/EntesService';

class MenuPrincipal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.getCollapseStates(props.routes),
      acessoPais: false,
      controleEstoque: false,
    };

    this.entesService = new EntesService();
  }

  async componentDidMount() {
    const acessosEnte = await this.entesService
      .carregarAcessosEnte(this.props.dadosUsuario.idEnte);
    
    this.setState({
      acessoPais: acessosEnte.acessoPais,
      acessoAlunos: acessosEnte.acessoAlunos,
      acessoTurmasVirtuais: acessosEnte.acessoTurmasVirtuais,
      educaCenso: acessosEnte.educaCenso,
      matriculasOnline: acessosEnte.matriculasOnline,
      relatoriosCoordenadores: acessosEnte.relatoriosCoordenadores,
      controleEstoque: acessosEnte.controleEstoque,
      transportes: acessosEnte.transportes,
      afastamentos: acessosEnte.afastamentos,
    });
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => (this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : '');

  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };

  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i += 1) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }

  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes) => routes.map((prop, key) => {
    if (prop.redirect) {
      return null;
    }
    if (prop.acessoPais && !this.state.acessoPais) {
      return null;
    }
    if (prop.educaCenso && !this.state.educaCenso) {
      return null;
    }
    if (prop.matriculasOnline && !this.state.matriculasOnline) {
      return null;
    }
    if (prop.acessoTurmasVirtuais && !this.state.acessoTurmasVirtuais) {
      return null;
    }
    if (prop.acessoPais_OU_acessoAlunos && !this.state.acessoPais && !this.state.acessoAlunos) {
      return null;
    }
    if (prop.relatoriosCoordenadores && !this.state.relatoriosCoordenadores) {
      return null;
    }
    if (prop.controleEstoque && !this.state.controleEstoque) {
      return null;
    }
    if (prop.transportes && !this.state.transportes) {
      return null;
    }
    if (prop.afastamentos && !this.state.afastamentos) {
      return null;
    }

    if (prop.collapse) {
      const st = {};
      st[prop.state] = !this.state[prop.state];
      return (
        <li
          className={this.getCollapseInitialState(prop.views) ? 'active' : ''}
          key={key}
        >
          <a
            href="#expand"
            data-toggle="collapse"
            aria-expanded={this.state[prop.state]}
            onClick={(e) => {
              e.preventDefault();
              this.setState(st);
            }}
          >
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <p>
                  {prop.name}
                  <b className="caret" />
                </p>
              </>
            ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">
                    {prop.name}
                    <b className="caret" />
                  </span>
                </>
            )}
          </a>
          <Collapse isOpen={this.state[prop.state]}>
            <ul className="nav">{this.createLinks(prop.views)}</ul>
          </Collapse>
        </li>
      );
    }

    return (
      <li className={this.activeRoute(prop.layout + prop.path)} key={key}>
        <NavLink to={prop.layout + prop.path} activeClassName="">
          {prop.icon !== undefined ? (
            <>
              <i className={prop.icon} />
              <p>{prop.name}</p>
            </>
          ) : (
              <>
                <span className="sidebar-mini-icon">{prop.mini}</span>
                <span className="sidebar-normal">{prop.name}</span>
              </>
          )}
        </NavLink>
      </li>
    );
  });

  render() {
    return <Nav>{this.createLinks(this.props.routes)}</Nav>;
  }
}

const mapStateToProps = (state) => ({ dadosUsuario: state.dadosUsuario });

export default connect(mapStateToProps)(MenuPrincipal);
