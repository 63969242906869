/* eslint-disable max-len */
import React from 'react';

import {
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import 'react-perfect-scrollbar/dist/css/styles.css';

import Utils from 'utils/Utils';

import Loader from 'react-loader-spinner';

import LoaderModal from 'views/components/LoaderModal';
import NumberFormat from 'react-number-format';

import EntesService from 'services/EntesService';
import TurmasService from 'services/TurmasService';
import DiariosService from 'services/DiariosService';

import Papel from 'assets/csv/papeis.json';
import SituacaoDiario from 'assets/csv/situacoes-diario.json';

class DiariosTurmaMultiseriado extends React.Component {
  constructor(props) {
    super(props);

    this.situacoes = [
      SituacaoDiario.ABERTO,
      SituacaoDiario.CONSOLIDADO,
    ];

    this.state = {
      loading: true,

      aulasALancar: '',
      turma: props.turma,
    };

    this.entesService = new EntesService();
    this.turmasService = new TurmasService();
    this.diariosService = new DiariosService();

    this.validarCampos = this.validarCampos.bind(this);
    this.atualizarAulasALancarDiarioMultiseriado = this.atualizarAulasALancarDiarioMultiseriado.bind(this);
  }

  async componentDidMount() {
    const dadosEnte = await this.entesService
      .carregarDadosEnte();

    const diarios = await this.turmasService
      .carregarDiariosTurmaMultiseriado(this.props.turma.id);

    this.setState({
      dadosEnte,
      aulasALancar: diarios[0].aulasALancar,
      situacaoDiarios: diarios[0].situacao,
      loading: false,
    });
  }

  diarioSelecionado() {
    this.props.history
      .push(`${this.props.layout}/anosletivos/${this.props.turma.anoLetivo.id}`
        + `/escola/${this.props.turma.escola.id}`
        + `/turma/multiseriado/${this.props.turma.id}/diario`);
  }

  validarCampos() {
    let ret = true;
    if (!this.state.aulasALancar && this.state.aulasALancar !== 0) {
      this.setState({ aulasALancarState: 'has-danger' });
      ret = false;
    }
    return ret;
  }

  async atualizarAulasALancarDiarioMultiseriado(e) {
    e.preventDefault();
    if (!this.validarCampos()) return;

    this.setState({
      showLoaderModal: true,
      loaderModalText: 'Atualizando...',
    });

    this.props.callbackMessage({ showAlert: false });
    try {
      await this.turmasService.atualizarAulasALancarDiarioMultiseriado(this.props.turma.id, this.state.aulasALancar);

      this.setState({
        showLoaderModal: false,
      });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Números de aulas dos diários atualizados com sucesso.',
      });
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
      });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao atualizar números de aulas dos diários.',
      });
    }
  }

  async imprimirDiarioMultiseriado() {
    this.setState({
      showLoaderModal: true,
      loaderModalText: 'Imprimindo diários para pdf...',
    });
    this.props.callbackMessage({
      showAlert: false,
    });
    try {
      const pdfs = await this.diariosService.imprimirDiarioMultiseriado(this.props.match.params.idTurma);

      pdfs.forEach((pdf) => {
        const file = Utils.base64ToBlob(pdf);
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      });

      this.setState({
        showLoaderModal: false,
      });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Diários impressos com sucesso. Caso o documento não seja visualizado, desabilite o bloqueador de pop-up para este site',
      });
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        showLoaderModal: false,
      });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao imprimir diários',
      });
    }
  }

  async imprimirAtaFinalMultiseriado() {
    this.setState({
      showLoaderModal: true,
      loaderModalText: 'Imprimindo atas finais para pdf...',
    });
    try {
      const pdfs = await this.diariosService.imprimirAtaFinalMultiseriado(this.props.match.params.idTurma);
      pdfs.forEach((pdf) => {
        const file = Utils.base64ToBlob(pdf);
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      });

      this.setState({
        showLoaderModal: false,
      });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Atas finais impressas com sucesso. Caso o documento não seja visualizado, desabilite o bloqueador de pop-up para este site',
      });
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        showLoaderModal: false,
      });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao imprimir atas finais',
      });
    }
  }

  async imprimirQuadroFrequencia() {
    this.setState({
      showLoaderModal: true,
      loaderModalText: 'Imprimindo quadro de frequência para pdf...',
    });

    try {
      const pdf = await this.diariosService.imprimirQuadroFrequenciaMultiseriado(this.props.match.params.idTurma);

      const file = Utils.base64ToBlob(pdf);
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);

      this.setState({
        showLoaderModal: false,
      });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Quadro de frequência impresso com sucesso. Caso o documento não seja visualizado, desabilite o bloqueador de pop-up para este site',
      });
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        showLoaderModal: false,
      });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao imprimir quadro de frequência',
      });
    }
  }

  async imprimirListaAssinaturaAlunos() {
    this.setState({
      loaderModalText: 'Imprimindo lista de assinaturas para pdf...',
      showAlert: false,
    });

    try {
      const pdf = await this.diariosService.imprimirListaAssinaturaAlunosMultiseriado(this.props.match.params.idTurma);

      const file = Utils.base64ToBlob(pdf);
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);

      this.setState({
        showLoaderModal: false,
      });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Lista de assinaturas impressa com sucesso. Caso o documento não seja visualizado, desabilite o bloqueador de pop-up para este site',
      });
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        showLoaderModal: false,
      });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao imprimir lista de assinaturas',
      });
    }
  }

  async imprimirRegistrosAula(idTurma) {
    this.setState({
      showLoaderModal: true,
      loaderModalText: 'Imprimindo registros de aula para pdf...',
      showAlert: false,
    });

    try {
      const pdf = await this.diariosService.imprimirRegistrosAulaMultiseriado(idTurma);

      const file = Utils.base64ToBlob(pdf);
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Registros de aula impressos com sucesso. Caso o documento não seja visualizado, desabilite o bloqueador de pop-up para este site',
      });
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao imprimir registros de aula',
      });
    }
  }

  async imprimirBoletinsAlunosMultiseriado(idTurma) {
    this.setState({
      showLoaderModal: true,
      loaderModalText: 'Imprimindo boletins dos alunos para pdf...',
      showAlert: false,
    });

    try {
      const pdfs = await this.diariosService.imprimirBoletinsAlunosMultiseriado(idTurma);

      pdfs.forEach((pdf) => {
        const file = Utils.base64ToBlob(pdf);
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      });

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Boletins dos alunos impressos com sucesso. Caso o documento não seja visualizado, desabilite o bloqueador de pop-up para este site',
      });
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao imprimir boletins dos alunos',
      });
    }
  }

  render() {
    return this.state.loading ? <div align="center" style={{ padding: 30 }}>
      <Loader
        type="Oval"
        color="#053d7c"
        height="50"
        width="50" />
    </div> : <>
      <Form onSubmit={this.atualizarAulasALancarDiarioMultiseriado}>
        <Row>
          <Col md="12">
            <Table hover>
              <thead className="text-primary">
                <tr>
                  <th style={{ width: '40%' }}>Nome</th>
                  <th style={{ width: '30%' }}>Situação</th>
                  <th style={{ width: '10%' }}>Aulas à lançar</th>
                  <th style={{ width: '10%' }}></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  className='tr-clickable'
                  onClick={() => this.diarioSelecionado()}>
                  <td>Diário unico</td>
                  <td>{this.situacoes[this.state.situacaoDiarios].label}</td>
                  <td>
                    <FormGroup className={`has-label ${this.state.aulasALancarState}`}>
                      <Input
                        disabled={this.state.situacaoDiarios
                          === SituacaoDiario.CONSOLIDADO.value
                          || this.props.role === Papel.COORDENADOR_PEDAGOGICO.value
                          || this.props.role === Papel.PROFESSOR.value}
                        value={this.state.aulasALancar}
                        decimalSeparator={false}
                        allowNegative={false}
                        onClick={(e) => e.stopPropagation()}
                        onValueChange={(val) => {
                          this.setState({
                            aulasALancar: val.formattedValue,
                            aulasALancarState: '',
                          });
                        }}
                        tag={NumberFormat} />
                      {this.state.aulasALancarState === 'has-danger' ? (
                        <label className="error">
                          Informe um número de aulas válido.
                        </label>
                      ) : null}
                    </FormGroup>
                  </td>
                  <td className="text-center">
                    <UncontrolledDropdown>
                      <DropdownToggle
                        onClick={(e) => e.stopPropagation()}
                        aria-haspopup={true}
                        color="default"
                        data-toggle="collapse"
                        id="navbarDropdownMenuLink"
                        nav>
                        <Button color="default" size="sm" type="button">
                          Opções <b className="caret" />
                        </Button>
                      </DropdownToggle>
                      <DropdownMenu aria-labelledby="navbarDropdownMenuLink" right>
                        {
                          this.props.role !== Papel.COORDENADOR_PEDAGOGICO.value
                          && <DropdownItem
                            onClick={(e) => {
                              e.stopPropagation();
                              this.imprimirDiarioMultiseriado(this.props.turma.id);
                            }}>
                            Imprimir diário
                          </DropdownItem>
                        }
                        {
                          this.props.role !== Papel.COORDENADOR_PEDAGOGICO.value
                          && <DropdownItem
                            onClick={(e) => {
                              e.stopPropagation();
                              this.imprimirAtaFinalMultiseriado(this.props.turma.id);
                            }}>
                            Imprimir ata final
                          </DropdownItem>
                        }
                        {
                          this.props.role !== Papel.COORDENADOR_PEDAGOGICO.value
                          && <DropdownItem
                            onClick={(e) => {
                              e.stopPropagation();
                              this.imprimirQuadroFrequencia(this.props.turma.id);
                            }}>
                            Imprimir quadro de frequência
                          </DropdownItem>
                        }
                        {
                          this.props.role !== Papel.COORDENADOR_PEDAGOGICO.value
                          && <DropdownItem
                            onClick={(e) => {
                              e.stopPropagation();
                              this.imprimirListaAssinaturaAlunos(this.props.turma.id);
                            }}>
                            Imprimir lista de assinatura dos alunos
                          </DropdownItem>
                        }
                        {
                          this.state.dadosEnte.registroAulas && <DropdownItem
                            onClick={(e) => {
                              e.stopPropagation();
                              this.imprimirRegistrosAula(this.props.turma.id)
                            }}>
                            Imprimir registros de aula
                          </DropdownItem>
                        }
                        <DropdownItem
                          onClick={(e) => {
                            e.stopPropagation();
                            this.imprimirBoletinsAlunosMultiseriado(this.props.turma.id)
                          }}>
                          Imprimir boletins dos alunos
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col className="text-left">
            <Button
              color="primary"
              onClick={() => this.props.history
                .push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.turma.escola.id}`)} >
              Voltar
            </Button>
          </Col>
          {
            this.props.role !== Papel.COORDENADOR_PEDAGOGICO.value
            && this.props.role !== Papel.PROFESSOR.value
            && <Col className="text-right">
              <Button color="primary"
                type="submit"
                disabled={this.state.showLoaderModal}>
                Atualizar Número de aulas
              </Button>
            </Col>
          }
        </Row>
      </Form>
      <LoaderModal
        isOpen={this.state.showLoaderModal}
        text={`${this.state.loaderModalText}`} />
    </>;
  }
}

export default DiariosTurmaMultiseriado;
