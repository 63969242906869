import React from 'react';

import {
  Row,
  Col,
  Button,
  UncontrolledTooltip
} from 'reactstrap';

import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

class PhotoGallery extends React.Component {

  constructor(props) {
    super(props);

    this.fileInputRef = React.createRef();

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleMovePrev = this.handleMovePrev.bind(this);
    this.handleMoveNext = this.handleMoveNext.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.fileChange = this.fileChange.bind(this);
    this.removeSelected = this.removeSelected.bind(this);

    this.state = {
      images: this.props.images || [],
      selectMode: this.props.selectMode,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      images: nextProps.images,
      selectMode: nextProps.selectMode,
    });
  }

  handleClick(index, image) {
    const images = this.state.images;

    const nextIndex = (index + 1) % images.length;
    const prevIndex = (index + images.length - 1) % images.length;

    this.setState({
      index,
      currentImage: image,
      nextImage: images[nextIndex],
      prevImage: images[prevIndex],
    });
  };

  handleClose() {
    this.setState({
      currentImage: undefined,
    });
  }

  handleMovePrev() {
    const images = this.state.images;
    const index = (this.state.index + images.length - 1) % images.length;

    const image = this.state.images[index];
    const nextIndex = (index + 1) % images.length;
    const prevIndex = (index + images.length - 1) % images.length;

    this.setState({
      index,
      currentImage: image,
      nextImage: images[nextIndex],
      prevImage: images[prevIndex],
    });
  }

  handleMoveNext() {
    const images = this.state.images;
    const index = (this.state.index + 1) % images.length;

    const image = this.state.images[index];
    const nextIndex = (index + 1) % images.length;
    const prevIndex = (index + images.length - 1) % images.length;

    this.setState({
      index,
      currentImage: image,
      nextImage: images[nextIndex],
      prevImage: images[prevIndex],
    });
  }

  onSelect(index) {
    Object.assign(this.state.images[index], {
      isSelected: !this.state.images[index].isSelected,
    });
    this.forceUpdate();
  }

  fileChange(e) {
    e.preventDefault();
    const files = e.target.files;

    const images = [];

    Array.from(files).forEach((file) => {
      if (file.type.startsWith('image/')) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {
            images.push({
              src: e.target.result,
              height: img.height,
              width: img.width,
              isSelected: false,
            });

            if (images.length === files.length) {
              const _images = [...this.state.images, ...images];
              this.props.onSelect(_images);
            }
          };

          img.src = e.target.result;
        };

        reader.readAsDataURL(file);
      }
    });
  }

  removeSelected() {
    const _images = this.state.images.filter((img) => !img.isSelected);
    this.props.onSelect(_images);
  }

  render() {
    return <>
      <Row>
        <Col>
          {
            this.state.selectMode && <>
              <div className="fileinput text-center">
                <input
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={this.fileChange}
                  ref={this.fileInputRef} />
              </div>
              <Button
                color="primary"
                disabled={this.state.showLoaderModal}
                onClick={() => this.fileInputRef.current.click()} >
                <i className="far fa-image"></i> Adicionar fotos
              </Button>
              {
                this.state.images.some((img) => img.isSelected) && <>
                  <Button
                    id="btnRemoverSelecionadas"
                    color="danger"
                    onClick={this.removeSelected}>
                    <i className="fas fa-trash-alt"></i>
                  </Button>
                  <UncontrolledTooltip target="btnRemoverSelecionadas" placement="bottom" >
                    Remover imagens selecionadas
                  </UncontrolledTooltip>
                </>
              }
            </>
          }
        </Col>
      </Row>
      {
        this.state.images && this.state.images.length > 0 && <Row>
          <Col>
            <Gallery
              images={this.state.images}
              rowHeight={280}
              onClick={this.handleClick}
              onSelect={this.state.selectMode ? this.onSelect : undefined}
            />
            {this.state.currentImage && (
              <Lightbox
                mainSrc={this.state.currentImage.src}
                nextSrc={this.state.nextImage.src}
                prevSrc={this.state.prevImage.src}
                onCloseRequest={this.handleClose}
                onMovePrevRequest={this.handleMovePrev}
                onMoveNextRequest={this.handleMoveNext}
              />
            )}
          </Col>
        </Row>
      }
    </>
  }
}

export default PhotoGallery;